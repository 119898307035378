import B2CClient from "../interfaces/B2CClient";
import B2CClientConnector from '../interfaces/B2CClientConnector';
import B2CClientConnectorProperty from '../interfaces/B2CClientConnectorProperty';
import B2CLicenceModel from '../interfaces/B2CLicenceModel';

import { ApiService } from './apiService';

export default class Business2CloudApiService {
  public static Instance = new Business2CloudApiService();

  private jwt: string | undefined;

  constructor() {
    if (Business2CloudApiService.Instance) throw new Error('Error: Initialization failed: Use Business2CloudApiService.Instance instead.');

    Business2CloudApiService.Instance = this;

    try {
      let cachedData = JSON.parse(localStorage.getItem('cachedData') as string);
      this.jwt = cachedData.jwt;
    } catch {
      this.jwt = undefined;
    }
  }

  private fetchRequest = async (method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT', endpoint: string, body: any = null): Promise<any> => {
    const options: any = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if ((!!body && method === 'POST') || method === 'PUT' || method === 'PATCH') {
      options.body = JSON.stringify(body);
    }

    if (this.jwt) options.headers['Authorization'] = 'Bearer ' + this.jwt;

    let response: any = await fetch(process.env.REACT_APP_PORTAL_API_URL + endpoint, options);
    let result = await response.text();

    if (!!result) {
      result = JSON.parse(result);
    }

    if ((result?.statusCode === 401 || result?.message === 'Unauthorized') && endpoint.indexOf('login') === -1) {
      return ApiService.logout();
    }

    return result;
  };

  public setJwt = (value: string) => (this.jwt = value);
  public getJwt = () => this.jwt;

  public getAdminGlobalConfiguration = async () => this.fetchRequest('GET', '/admin/globalconfiguration');
  public setAdminGlobalConfiguration = async (key: string, value: any) => this.fetchRequest('POST', '/admin/globalconfiguration', { key, value });

  public getConnectorInsightsAsync = (): Promise<any> => this.fetchRequest('GET', '/business2cloud/connectors/insights');

  public getAllClientsAsync = async (): Promise<B2CClient[]> =>
    this.fetchRequest('GET', '/business2cloud/clients');

  public setClientsAsync = async (client: B2CClient): Promise<B2CClient> =>
    this.fetchRequest('POST', '/business2cloud/clients', client);

  public getAllClientConnectorsAsync = async (type: string, excludeProperties: boolean = false): Promise<B2CClientConnector[]> =>
    this.fetchRequest('GET', `/business2cloud/clients/connectors/${type}?excludeProperties=${excludeProperties}`);

  public getAllClientConnectorAsync = async (type: string, excludeProperties: boolean = false): Promise<B2CClientConnector[]> =>
    this.fetchRequest('GET', `/business2cloud/clients/connectors/${type}?excludeProperties=${excludeProperties}`);

  public getAllClientConnectorLicensesAsync = async (organisationId: string, type: string): Promise<B2CLicenceModel[]> =>
    this.fetchRequest('GET', `/business2cloud/clients/connectors/${type}/licences`);

  public getClientConnectorsByTypeAsync = async (organisationId: string, type: string): Promise<B2CClientConnector[]> =>
    this.fetchRequest('GET', `/business2cloud/clients/${organisationId}/connectors/${type}`);

  public getOrganisationConnectorTypesAsync = async (organisationId: string): Promise<any[]> =>
    this.fetchRequest('GET', `/business2cloud/clients/${organisationId}/connector-types`);

  public getConnectorTypesAsync = async (): Promise<any[]> => this.fetchRequest('GET', `/business2cloud/clients/connector-types`);

  public getClientConnectorAsync = async (organisationId: string, clientConnectorId: number): Promise<B2CClientConnector> =>
    this.fetchRequest('GET', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}`);

  public getClientConnectorLicencesAsync = async (organisationId: string, clientConnectorId: number): Promise<B2CLicenceModel[]> =>
    this.fetchRequest('GET', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}/licences`);

  public setClientConnectorLicencesAsync = async (
    organisationId: string,
    clientConnectorId: number,
    licenceModels: B2CLicenceModel[]
  ): Promise<void> => this.fetchRequest('POST', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}/licences`, licenceModels);

  public getClientConnectorPropertiesAsync = async (organisationId: string, clientConnectorId: number): Promise<B2CClientConnectorProperty[]> =>
    this.fetchRequest('GET', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}/properties`);

  public setClientConnectorAsync = async (organisationId: string, clientConnector: B2CClientConnector): Promise<void> =>
    this.fetchRequest('PATCH', `/business2cloud/clients/${organisationId}/connectors/${clientConnector.Id}`, clientConnector);

  public addClientConnectorAsync = async (organisationId: string, clientConnector: B2CClientConnector): Promise<B2CClientConnector> =>
    this.fetchRequest('POST', `/business2cloud/clients/${organisationId}/connectors`, clientConnector);

  public setClientConnectorPropertiesAsync = async (
    organisationId: string,
    clientConnectorId: number,
    properties: B2CClientConnectorProperty[]
  ): Promise<void> => this.fetchRequest('PATCH', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}/properties`, properties);

  public setClientConnectorPropertyAsync = async (
    organisationId: string,
    clientConnectorId: number,
    clientConnectorPropertyId: number
  ): Promise<B2CClientConnectorProperty[]> =>
    this.fetchRequest('PATCH', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}/properties/${clientConnectorPropertyId}`);

  public addClientConnectorPropertyAsync = async (
    organisationId: string,
    clientConnectorId: number,
    clientConnectorProperty: B2CClientConnectorProperty
  ): Promise<void> =>
    this.fetchRequest('POST', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}/properties`, clientConnectorProperty);

  public removeClientConnectorPropertyAsync = async (
    organisationId: string,
    clientConnectorId: number,
    clientConnectorPropertyId: number
  ): Promise<void> =>
    this.fetchRequest('DELETE', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}/properties/${clientConnectorPropertyId}`);

  public processClientConnectorAsync = async (organisationId: string, clientConnectorId: number): Promise<void> =>
    this.fetchRequest('POST', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}/process`);

  public assignClientConnectorOrganisationsAsync = async (clientConnectorId: number, organisations: any[]): Promise<void> =>
    this.fetchRequest('POST', `/business2cloud/clients/connectors/${clientConnectorId}/assign-organisations`, { organisations });

  public assignClientConnectorUsersAsync = async (clientConnectorId: number, users: any[]): Promise<void> =>
    this.fetchRequest('POST', `/business2cloud/clients/connectors/${clientConnectorId}/assign-users`, { users });

  public disconnectClientConnectorAsync = async (organisationId: string, clientConnectorId: number): Promise<void> =>
    this.fetchRequest('DELETE', `/business2cloud/clients/${organisationId}/connectors/${clientConnectorId}`);
}

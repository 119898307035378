import { ChangeEvent, createRef, useContext, useEffect, useLayoutEffect, useState, Fragment } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { useSearchParams } from 'react-router-dom';
import { isArray } from 'lodash';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

import Form from './Form';
import FormSection from './FormSection';
import FormInput from './FormInput';
import pluralize from 'pluralize';
import SubAreaCard from './SubAreaCard';

const SubAreas = () => {
  const store = useContext(Store).store;
  const containerRef = createRef<any>();

  const [searchParams] = useSearchParams();
  const [subAreaList, setSubAreaList] = useState<any[]>([]);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalLoader, setShowModalLoader] = useState(false);
  const [selectedSubArea, setSelectedSubArea] = useState<any>();
  const [additionalSubAreas, setAdditionalSubAreas] = useState<string[]>([]);
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState(false);
  const [error, setError] = useState<any>('');
  const [hideError, setHideError] = useState(false);

  const search = searchParams.get('search') as string;

  const onScroll = () => containerRef?.current;

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  const loadSubAreas = (callback?: () => void) => {
    setShowLoader(true);

    ApiService
      .getInstance()
      .getSubAreas(store?.organisationSelected?.organisationId)
      .then((response) => {
        const subAreaListSorted = sortList(response, ['Name'], SORT_DIRECTION.ASC);
        setSubAreaList(subAreaListSorted);

        if (callback) callback();

        setShowLoader(false);
      });
  };

  const loadStatuses = (callback?: () => void) => {
    ApiService
      .getInstance()
      .getStatuses()
      .then((response) => {
        setStatusList(response);

        if (callback) callback();
      });
  };

  const onSubAreaChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubArea({
      ...selectedSubArea,
      [(event.currentTarget || event.target).id]: (event.currentTarget || event.target).value,
    } as any);
  };

  const onAdditionalSubAreaChange = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedAdditionalSubAreas = [...additionalSubAreas];

    updatedAdditionalSubAreas[parseInt(event.currentTarget.id)] = event.currentTarget.value;

    setAdditionalSubAreas(updatedAdditionalSubAreas);
  };

  const onAddClick = () => {
    setSelectedSubArea(undefined);
    setShowModal(true);
  };

  const onEditClick = (area: any) => {
    setSelectedSubArea(area);
    setShowModal(true);
  };

  const onDeleteClick = (area: any) => {
    setSelectedSubArea(area);
    setShowDeletionConfirmationModal(true);
  };

  const onAddAdditionalAreaClick = () => {
    setAdditionalSubAreas([...additionalSubAreas, '']);
  };

  const onRemoveAdditionalAreaClick = (index: number) => {
    let updatedAdditionalSubAreas = [...additionalSubAreas];

    updatedAdditionalSubAreas.splice(index, 1);

    setAdditionalSubAreas(updatedAdditionalSubAreas);
  };

  const onModalCancelClick = () => {
    setShowModal(false);
    setSelectedSubArea(undefined);
    setAdditionalSubAreas([]);
    setShowModalLoader(false);
  };

  const onModalSaveClick = () => {
    setShowModalLoader(true);

    if (selectedSubArea.Id) {
      ApiService
        .getInstance()
        .updateSubArea(selectedSubArea.Name, store?.organisationSelected?.organisationId, selectedSubArea.Id, selectedSubArea.StatusId)
        .then((response) => {
          debugger;
          if (response !== true && Math.floor(response.status / 200) !== 2) {
            if (response.message) {
              if (isArray(response.message))
                setError(
                  <div>
                    <b>The following errors occurred:</b>
                    <ul style={{ margin: 0 }}>
                      {response.message.map((_: any) => (
                        <li>{_}</li>
                      ))}
                    </ul>
                  </div>
                );
              else setError(<div>{response.message}</div>);
            } else if (response.error) {
              setError(response.error);
            }
          } else {
            loadSubAreas(() => {
              setShowModal(false);
              setSelectedSubArea(undefined);
              setShowModalLoader(false);
            });
          }
        });
    } else {
      let promises = [];

      promises.push(ApiService.getInstance().createSubArea(selectedSubArea.Name, store?.organisationSelected?.organisationId));

      for (let additionalArea of additionalSubAreas) {
        promises.push(ApiService.getInstance().createSubArea(additionalArea, store?.organisationSelected?.organisationId));
      }

      Promise.all(promises).then((responses) => {
        let errors = [] as any[];

        for (const response of responses) {
          if (Math.floor(response.status / 200) !== 2) {
            if (response.message) {
              if (isArray(response.message)) errors = [...errors, ...response.message];
              else errors = [...errors, response.message];
            } else if (response.error) {
              errors = [...errors, ...response.error];
            }
          }
        }

        if (errors.length) {
          setError(
            <div>
              <b>The following errors occurred:</b>
              <ul style={{ margin: 0 }}>
                {errors.map((_: any) => (
                  <li>{_}</li>
                ))}
              </ul>
            </div>
          );
        } else {
          loadSubAreas(() => {
            setShowModal(false);
            setSelectedSubArea(undefined);
            setAdditionalSubAreas([]);
          });
        }

        setShowModalLoader(false);
      });
    }
  };

  const onConfirmDeleteClick = () => {
    ApiService.getInstance()
      .deleteSubArea(selectedSubArea.Name, store?.organisationSelected?.organisationId, selectedSubArea.Id)
      .then((response) => {
        console.log(response);
        if (Math.floor(response.status / 200) !== 2) {
          if (response.message) {
            if (isArray(response.message))
              setError(
                <div>
                  <b>The following errors occurred:</b>
                  <ul style={{ margin: 0 }}>
                    {response.message.map((_: any) => (
                      <li>{_}</li>
                    ))}
                  </ul>
                </div>
              );
            else setError(<div>{response.message}</div>);
          } else if (response.error) {
            setError(response.error);
          }
        } else {
          loadSubAreas(() => {
            setShowDeletionConfirmationModal(false);
          });
        }
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => loadStatuses(loadSubAreas), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadSubAreas(), [store?.organisationSelected?.organisationId]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <>
      {showDeletionConfirmationModal && (
        <div className="modal modal-sm">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Form>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Delete</h5>
                      <span>
                        Are you sure you want to delete <b>{selectedSubArea?.Name}</b>?
                      </span>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div className="d-flex flex-row gap-2 justify-content-end">
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowDeletionConfirmationModal(false)}>
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-sm btn-danger" onClick={onConfirmDeleteClick}>
                        Delete
                      </button>
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      {showModal && (
        <Form onSubmit={() => {}}>
          <div className="modal modal-sm">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title">{additionalSubAreas.length > 0 ? 'Multiple sub-areas' : selectedSubArea?.Name || 'New sub-area'}</h5>
                    <span className="light-text">Sub-area properties</span>
                  </div>
                </div>
                <div className="modal-body">
                  <FormSection>
                    <FormInput
                      id="Name"
                      name="Name"
                      label={pluralize('Name', additionalSubAreas.length + 1)}
                      autoComplete="off"
                      required={true}
                      value={selectedSubArea?.Name}
                      placeholder="Name"
                      onChange={onSubAreaChange}
                    />
                    {additionalSubAreas.map((additionalArea, index) => (
                      <div key={index} className="flex gap-2 align-items-center">
                        <FormInput className="flex-grow-1" id={index} name="Name" autoComplete="off" required={true} value={additionalArea} placeholder="Name" onChange={onAdditionalSubAreaChange} />
                        <button className="btn btn-sm btn-round btn-secondary btn-danger" title="Remove" onClick={() => onRemoveAdditionalAreaClick(index)}>
                          <span className="material-symbols-rounded">remove</span>
                        </button>
                      </div>
                    ))}
                    {!selectedSubArea?.Id && (
                      <button className="btn btn-sm btn-primary justify-content-center" onClick={onAddAdditionalAreaClick}>
                        <span className="material-symbols-rounded m-auto">add_circle</span>
                      </button>
                    )}
                    {selectedSubArea?.Id && (
                      <FormInput
                        id="StatusId"
                        name="StatusId"
                        label="Status"
                        type="select"
                        items={statusList.map((_) => ({ key: _.Id, value: _.Name }))}
                        autoComplete="off"
                        value={selectedSubArea?.Status?.Id}
                        placeholder="Status"
                        onChange={onSubAreaChange}
                        className="text-capitalize"
                      />
                    )}
                  </FormSection>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-sm btn-outline-dark" onClick={onModalCancelClick}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary" onClick={onModalSaveClick}>
                    Save
                  </button>
                </div>
                {showModalLoader && (
                  <div className="modal-loader">
                    <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        </Form>
      )}
      <div className="page-buttons">
        <div className="flex flex-row gap-2">
          <button className="btn btn-sm btn-primary justify-content-center" onClick={onAddClick}>
            Add Sub-area(s)
          </button>
        </div>
      </div>
      <div className="grid" ref={containerRef}>
        {error && (
          <div className={`error-message sticky ${hideError ? 'hide' : ''}`}>
            <span>
              {error}
              <i className="material-symbols-rounded" onClick={onErrorHideClick}>
                cancel
              </i>
            </span>
          </div>
        )}
        {subAreaList
          .filter((subarea) => !search || subarea.Name.toLowerCase().indexOf(search) >= 0)
          .map((subarea) => (
            <SubAreaCard key={subarea.Id} subarea={subarea} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
          ))}
      </div>
      {showLoader && (
        <div className="dashboard__content__loader">
          <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
          </svg>
        </div>
      )}
    </>
  );
};

export default withRouter(SubAreas);

import { useContext, useEffect, useState } from 'react';
import { Store } from '../store/Store';
import { orderBy, take } from 'lodash';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';

const colors = require('ac-colors');

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const AreaCard = ({
  area,
  onEditClick,
  onDeleteClick,
  onManageSubAreasClick,
}: {
  area: any;
  onEditClick: (area: any) => void;
  onDeleteClick: (area: any) => void;
  onManageSubAreasClick: (area: any) => void;
}) => {
  const [showLoader] = useState(false);
  const [subareaList, setSubAreaList] = useState(orderBy(area.Subareas, _ => _.Name));
  const [subareaLinkType, setSubareaLinkType] = useState(area.SubareaLinkType);

  const store = useContext(Store).store;

  area.Status = area.Status || { Name: 'active' };

  useEffect(() => setSubAreaList(orderBy(area.Subareas, _ => _.Name)), [area.Subareas]);
  useEffect(() => setSubareaLinkType(area.SubareaLinkType), [area.SubareaLinkType]);

  console.log(area.Name, area.Subareas);

  return (
    <div className="card card-sm grid-item" style={{ height: 230 }}>
      <div className="card-header align-items-center">
        <div className="d-flex flex-column gap-3 me-auto">
          <span className="text-uppercase fw-bold">
            {(area.Status.Name.toLowerCase() === 'active' && (
              <span className="p-1 px-2 fs-11 text-success bg-success bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                active
              </span>
            )) ||
              (area.Status.Name.toLowerCase() === 'archived' && (
                <span className="p-1 px-2 fs-11 text-dark bg-dark bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                  archived
                </span>
              )) ||
              (area.Status.Name.toLowerCase() === 'locked' && (
                <span className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                  locked
                </span>
              ))}
          </span>
          <span className="d-flex flex-column">
            <small className="fw-bold lighter-text fs-11">{area.Id}</small>
            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>{area.Name}</span>
          </span>
        </div>
      </div>
      <div className="card-body">
        {subareaLinkType === 'custom' ? (
          <>
            {subareaList && (
              <div className="status-label-stack">
                {take(subareaList, 10).map((subarea: any, index: number) => {
                  let baseColor = colors.randomFromString(subarea.Name);
                  let backgroundColor = colors.blend(baseColor, new colors([255, 255, 255]), 'hex', 0.9)._hex;

                  return (
                    <span className="status-label" key={index} style={{ backgroundColor }} title={`${subarea.Name}`}>
                      {subarea.Name.charAt(0)}
                    </span>
                  );
                })}
                {subareaList.length > 10 && <span className="status-label additional">+ {subareaList.length - 10}</span>}
              </div>
            )}
          </>
        ) : (
          <div className="status-label-stack">
            <span className="status-label long">
              <span className="status-label mini inset" style={{ backgroundColor: '#999' }}></span>
              <span className="status-label mini inset" style={{ backgroundColor: '#aaa' }}></span>
              <span className="status-label mini inset" style={{ backgroundColor: '#bbb' }}></span>
              All Sub-areas
            </span>
          </div>
        )}
      </div>
      <div className="card-footer gap-2 mt-auto">
        <div className="d-flex flex-row gap-1">
          <button className="btn btn-sm btn-primary" onClick={() => onManageSubAreasClick(area)}>
            Manage linked sub-areas
          </button>
          <div className="d-flex flex-row gap-2 flex-grow ms-auto">
            <button className="btn btn-sm btn-round btn-secondary" title="Edit" onClick={() => onEditClick(area)}>
              <span className="material-symbols-rounded">edit</span>
            </button>
            {store.user.roleLevel <= 1 && (
              <button className="btn btn-sm btn-round btn-secondary btn-danger" title="Delete" onClick={() => onDeleteClick(area)}>
                <span className="material-symbols-rounded">delete</span>
              </button>
            )}
          </div>
        </div>
      </div>
      {showLoader && (
        <div className="card-loader">
          <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default AreaCard;

import { createRef, useReducer, useState, useEffect } from 'react';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';

import ReCAPTCHA from 'react-google-recaptcha';
import Form from './Form';
import FormSection from './FormSection';
import FormInput from './FormInput';
import { PAGE_URLS } from '../routes/routes';

const ResetPasswordRequest = () => {
  const recaptchaRef = createRef<any>();
  const formRef = createRef<any>();

  const reducer = (state: any, value: any) => ({
    ...state,
    [value.id]: value.value,
  });

  const [error, setError] = useState('');
  const [hideError, setHideError] = useState(false);
  const [errorSticky, setErrorSticky] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errorTop, setErrorTop] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [model, setModel] = useReducer(reducer, {
    emailAddress: '',
  });

  const onFormInputChange = (event: any) => setModel(event.target);
  const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);
  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  const requestResetPassword = async () => {
    setShowLoader(true);

    recaptchaRef.current.reset();

    const token = await recaptchaRef.current.executeAsync();
    const result = await ApiService.getInstance().resetPasswordRequest({
      emailAddress: model.emailAddress,
      recaptchaToken: token,
    });

    if (result.status == 201 || result.status == 200) {
      setShowSuccess(true);
    } else if (result.status == 400 || result.status == 500) {
      setError(result?.message);
    }

    setShowLoader(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });
  useEffect(() => setErrorSticky(errorTop < 0), [errorTop]);

  const validateEmailAddress = (value: string) => {
    const expression =
      /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/;

    if (!expression.test(value || '')) {
      return {
        valid: false,
        message: 'Invalid email address',
      };
    }

    return {
      valid: true,
    };
  };

  if (showSuccess) {
    return (
      <div className='login-container container'>
        <div className='row justify-content-center'>
          <div className='col-11 col-sm-10 col-md-10 col-lg-8 col-xl-6'>
            <div className='m-4 mt-5'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
                <span className='text-center mt-2 text-nowrap light-text'>
                  Reset your <b>Business2Cloud</b> password
                </span>
              </div>
            </div>
            <div className='card card-sm mb-5'>
              <div className='card-body'>
                <div className='form'>
                  <FormSection>
                    <h5>Your reset password link is on the way</h5>
                    <span>
                      If there is a Business2Cloud account for <b>{model.emailAddress}</b>, we'll send a reset link there to create a new password.
                    </span>
                    <span className='light-text'>
                      If you don't receive an email with a reset link within the next 15 minutes, please check your spam folder and adjust your
                      filtering to allow emails from larasoft.global.
                    </span>
                    <a href={PAGE_URLS.SIGNIN} className='btn btn-sm btn-primary me-auto mt-3'>
                      <span className='material-symbols-rounded'>arrow_back_ios</span>
                      Return to sign in
                    </a>
                  </FormSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='login-container container'>
      <div className='row justify-content-center'>
        <div className='col-11 col-sm-10 col-md-7 col-lg-5 col-xl-4'>
          <div className='m-4 mt-5'>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
              <span className='text-center mt-2 text-nowrap light-text'>
                Reset your <b>Business2Cloud</b> password
              </span>
            </div>
          </div>
          <div className='card card-sm mb-5' ref={formRef}>
            <div className='card-body'>
              <div className='form'>
                {error && (
                  <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                    <span>
                      {error}
                      <span className='material-symbols-rounded' onClick={onErrorHideClick}>
                        cancel
                      </span>
                    </span>
                  </div>
                )}
                <div>
                  <Form onSubmit={requestResetPassword}>
                    <FormSection>
                      <div className='form-section-header'>
                        <h5>Reset your password</h5>
                        <span>
                          Provide the email address associated with your account and we will send you an email with a link to reset your password.
                        </span>
                      </div>
                      <FormInput
                        id='emailAddress'
                        placeholder='Email address'
                        required={true}
                        value={model.emailAddress}
                        onChange={onFormInputChange}
                        validate={validateEmailAddress}
                      />
                      <button className='btn btn-sm btn-secondary me-auto mt-3' type='submit'>
                        Reset password
                      </button>
                    </FormSection>
                  </Form>
                  <ReCAPTCHA size='invisible' ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string} />
                </div>
              </div>
            </div>
            {showLoader && (
              <div className='card-loader'>
                <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                  <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPasswordRequest);

import { useContext, useEffect, useState } from 'react';
import { Store } from '../store/Store';

import Business2CloudApiService from '../services/Business2CloudApiService';
import dayjs from 'dayjs';

const Admin = () => {
  const storeContext = useContext(Store);

  const [connectorInsights, setConnectorInsights] = useState<any[]>([]);
  const [showCopied, setShowCopied] = useState(false);

  const groupConnectors = (connectors: any[]) =>
    connectors.reduce((result, item) => {
      (result[item.ClientName] = result[item.ClientName] || []).push(item);
      return result;
    }, []);

  useEffect(() => {
    Business2CloudApiService.Instance.getConnectorInsightsAsync().then((response) => setConnectorInsights(groupConnectors(response)));

    const intervalId = setInterval(() => {
      Business2CloudApiService.Instance.getConnectorInsightsAsync().then((response) => setConnectorInsights(groupConnectors(response)));
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const onCopyKeyToClipboardClick = () => {
    if (storeContext.store?.organisationSelected?.organisationId) {
      navigator.clipboard.writeText(storeContext.store?.organisationSelected?.organisationId as string);

      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 3000);
    }
  };

  console.log(Object.keys(connectorInsights).reduce((result) => result++, 0));

  return (
    <>
      <div className='page-sub-heading'>
        <h4>Organisation details</h4>
      </div>
      <div className='fancy-list fancy-list__vertical col-12 col-lg-8 col-xl-6'>
        <div>
          <span className='d-flex flex-row align-items-center'>
            <span className='d-flex flex-column'>
              <label>Id</label>
              <span className='light-text'>{storeContext.store?.organisationSelected?.organisationId}</span>
            </span>
            {showCopied && (
              <span className='ms-auto btn btn-success btn-xs btn-round'>
                <span className='material-symbols-rounded'>check</span>
              </span>
            )}
            {!showCopied && (
              <button className='ms-auto btn btn-outline-secondary btn-xs btn-round' onClick={onCopyKeyToClipboardClick}>
                <span className='material-symbols-rounded'>content_copy</span>
              </button>
            )}
          </span>
        </div>
        <div>
          <label>Name</label>
          <span className='light-text'>{storeContext.store?.organisationSelected?.data.CompanyName}</span>
        </div>
      </div>
      <div className='page-divider'></div>
      <div className='page-sub-heading'>
        <h4>Insights</h4>
      </div>
      {Object.keys(connectorInsights).length > 0 ? (
        <div className='fancy-list fancy-list__vertical col-12 col-lg-8 col-xl-6'>
          <div>
            <div>
              <label>Active connectors</label>
              <span className='d-flex me-auto align-items-center'>
                {Object.keys(connectorInsights).reduce((result, item: string) => result + (connectorInsights as any)[item].length, 0)}
              </span>
            </div>
          </div>
          {Object.keys(connectorInsights).map((client: any) => (
            <div key={client}>
              <label>{client}</label>
              {connectorInsights[client] &&
                connectorInsights[client].map &&
                connectorInsights[client].map((connector: any) => (
                  <div key={connector.ClientConnectorId} className='flex flex-column inset'>
                    <span className='flex flex-row align-items-center gap-2'>{connector.ClientConnectorName}</span>
                    <small className='light-text'>
                      Started <b>{dayjs(connector.AutomaticallyTriggeredAtUtc ?? connector.ManuallyTriggeredAtUtc).fromNow()}</b>
                    </small>
                  </div>
                ))}
            </div>
          ))}
        </div>
      ) : (
        <label>No connectors are running at the moment.</label>
      )}
    </>
  );
};

export default Admin;

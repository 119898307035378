import React, { ChangeEvent, Fragment, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Store } from '../store/Store';
import { REDUCER_ACTION_SET } from '../store/types';
import { PAGE_URLS } from '../routes/routes';
import { ApiService } from '../services/apiService';
import { withRouter } from '../routes/withRouter';
import { debounce } from 'lodash';
import Business2CloudApiService from '../services/Business2CloudApiService';
import B2CColors from "../common/b2cColors";

const Nav = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { children } = props;
  const { connectorType: currentConnectorType } = useParams();

  const store = useContext(Store).store;
  const dispatch = useContext(Store).dispatch;
  const userName = store.user ? `${store.user.firstName} ${store.user.lastName}` : '';
  const userNameInitials = store?.user?.firstName?.charAt(0) + '' + store?.user?.lastName?.charAt(0);

  const [toggleNavigation, setToggleNavigation] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showConnectorSkeleton, setShowConnectorSkeleton] = useState(true);

  const location = useLocation();

  const handleResize = () => {
    if (window.innerWidth < 575) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!store.organisationSelected) return;

    Business2CloudApiService.Instance.getOrganisationConnectorTypesAsync(store.organisationSelected.data.Id).then((response: any) => {
      dispatch({
        type: REDUCER_ACTION_SET.SET_ORGANISATION_CONNECTOR_TYPES,
        payload: response,
      });
    });
    Business2CloudApiService.Instance.getConnectorTypesAsync().then((response: any) => {
      dispatch({
        type: REDUCER_ACTION_SET.SET_CONNECTOR_TYPES,
        payload: response,
      });
    });
  }, [store.organisationSelected, store.connectorTypeCacheBust, dispatch]);

  useEffect(() => {
    if (store?.organisationConnectorTypes) setShowConnectorSkeleton(false);
  }, [store?.organisationConnectorTypes]);

  useEffect(() => {
    if (isMobile) {
      setToggleNavigation(false);
    }
  }, [isMobile, location, store.organisationSelected]);

  useEffect(() => {
    handleResize();

    if (window.innerWidth >= 575) {
      const _toggleNavigation = document.cookie
        .split('; ')
        .find((_) => _.startsWith('nav='))
        ?.split('=')[1];

      setToggleNavigation(_toggleNavigation === 'true');
    } else {
      setToggleNavigation(false);
    }

    setTimeout(() => setAnimate(true), 500);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      document.cookie = `nav=${toggleNavigation}`;
    }
  }, [toggleNavigation]);

  useLayoutEffect(() => {
    ApiService.getInstance()
      .getOrganisations()
      .then((response) => {
        dispatch({
          type: REDUCER_ACTION_SET.SET_ORGANISATIONS,
          payload: response,
        });

        let organisation = searchParams.get('organisation');
        let selectedOrganisation = !!organisation
          ? response.find((_: any) => _.Id.toLowerCase() === organisation?.toLowerCase())
          : store?.organisationSelected?.data || response.find((_: any) => true);

        dispatch({
          type: REDUCER_ACTION_SET.SET_ORGANISATION_SELECTED,
          payload: {
            organisationId: selectedOrganisation.Id,
            data: selectedOrganisation,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeOrganisation = (e: ChangeEvent<HTMLSelectElement>, name: string) => {
    let selectedOrganisation = store.organisations.find((_: any) => _.Id.toLowerCase() === e.target.value.toLowerCase());

    dispatch({
      type: REDUCER_ACTION_SET.SET_ORGANISATION_SELECTED,
      payload: {
        organisationId: selectedOrganisation.Id,
        data: selectedOrganisation,
      },
    });
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      searchParams.set('search', event.target.value.toLowerCase());
    } else {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);
  };

  const onSearchChangeDebounce = debounce(onSearchChange, 300);

  return (
    <div className="dashboard">
      <nav className={`nav__sidebar ${toggleNavigation ? '' : 'minimised'} ${animate ? 'animate' : ''}`}>
        <div className="nav__sidebar-header">
          <NavLink to={PAGE_URLS.PROFILE} className={`nav__username ${location.pathname.indexOf('/profile') >= 0 ? 'active' : ''}`}>
            <div className="nav__username-initial" title={userName}>
              {userNameInitials}
            </div>
            <div className="d-flex flex-column" style={{ overflow: 'hidden' }}>
              {store.user.firstName}
              <small className="d-inline-block" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {store.user.email}
              </small>
            </div>
          </NavLink>
          <div className="nav__toggle ms-auto" onClick={() => setToggleNavigation(!toggleNavigation)}>
            <span className="material-symbols-rounded">{toggleNavigation ? 'menu_open' : 'menu'}</span>
          </div>
        </div>
        <div className="nav__sidebar-content">
          <div className="nav__sidebar-sticky">
            <NavLink to={PAGE_URLS.PROFILE} className={`nav__username ${location.pathname.indexOf('/profile') >= 0 ? 'active' : ''}`}>
              <div className="nav__username-initial" title={userName}>
                {userNameInitials}
              </div>
              <div className="d-flex flex-column" style={{ overflow: 'hidden' }}>
                {store.user.firstName}
                <small className="d-inline-block" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {store.user.email}
                </small>
              </div>
            </NavLink>
            <div className="nav__organisation" title="Organisation">
              <span className="nav__organisation-initial">{store.organisationSelected?.data.CompanyName?.charAt(0)}</span>
              <select id="selectRole" className="form-select" defaultValue={store.organisationSelected?.data.Id} onChange={(e) => handleChangeOrganisation(e, 'activeOrganisationIndex')}>
                {store.organisations &&
                  store.organisations
                    .sort((a: any, b: any) => a.CompanyName.localeCompare(b.CompanyName))
                    .map((organisation: any, index: number) => {
                      return (
                        <option key={index} value={organisation.Id}>
                          {organisation.CompanyName}
                        </option>
                      );
                    })}
              </select>
            </div>
            <div className="nav__divider_line"></div>
            <NavLink to={PAGE_URLS.HOME} className="nav__link nav__link--main">
              <span className="material-symbols-rounded">home</span>
              Home
            </NavLink>
            {store?.user?.roleLevel <= 2 && (
              <>
                <NavLink
                  to={store.user.roleLevel <= 1 ? PAGE_URLS.ADMIN_DASHBOARD : PAGE_URLS.ADMIN_USERS}
                  className={`nav__link nav__link--main ${location.pathname.indexOf('/admin') >= 0 ? 'active' : ''}`}>
                  <span className="material-symbols-rounded">manage_accounts</span>
                  Admin
                </NavLink>
              </>
            )}
            {store?.organisationConnectorTypes?.length > 0 && (
              <>
                <div className="nav__divider_line"></div>
                <div className="nav__divider">connectors</div>
              </>
            )}
          </div>
          {showConnectorSkeleton ? (
            <div className="nav__link--skeleton-wrapper">
              <div className="text-left justify-content-start nav__link nav__link--skeleton nav__link--main"></div>
              <div className="text-left justify-content-start nav__link nav__link--skeleton nav__link--main"></div>
              <div className="text-left justify-content-start nav__link nav__link--skeleton nav__link--main"></div>
              <div className="text-left justify-content-start nav__link nav__link--skeleton nav__link--main"></div>
            </div>
          ) : (
            <>
              {store?.organisationConnectorTypes?.map(
                (connectorType: any) =>
                  (connectorType.Type.toLowerCase() !== 'excel' || store?.user?.roleLevel <= 3) && (
                    <NavLink
                      key={connectorType.Id}
                      to={
                        connectorType.Type.toLowerCase() === 'excel' ? PAGE_URLS.EXCEL_AREAS : PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_REFRESH.replace(':connectorType', connectorType.Type.toLowerCase())
                      }
                      className={`text-left justify-content-start nav__link nav__link--main ${location.pathname.indexOf(`/${connectorType.Type.toLowerCase()}`) >= 0 ? 'active' : ''}`}>
                      <span className={`${B2CColors.Providers(connectorType.Type)?.background} tw-flex tw-text-xs tw-size-7 tw-min-w-7 tw-min-h-7 tw-rounded-full tw-items-center tw-justify-center tw-text-gray-100 tw-font-semibold`}>
                        {connectorType.Type[0]}
                      </span>
                      {connectorType.Type}
                    </NavLink>
                  )
              )}
            </>
          )}
        </div>
        <div className="nav__sidebar-footer mt-auto">
          <div className="nav__logo">
            <img src="/img/b2c-logo-dark.svg" alt="" />
          </div>
          <NavLink
            title="Logout"
            to="/"
            className="btn-secondary btn-round btn-sm nav__username-logout ms-auto"
            onClick={() => {
              localStorage.setItem('cachedData', '');
              localStorage.setItem('cachedDataStore', '');
            }}>
            <span className="material-symbols-rounded">logout</span>
          </NavLink>
        </div>
      </nav>
      <div className="dashboard__main">
        <nav className="nav__top">
          {location.pathname.indexOf(PAGE_URLS.PROFILE) >= 0 && (
            <>
              <h3 className="nav__top-title">Profile</h3>
            </>
          )}
          {location.pathname.indexOf(PAGE_URLS.ADMIN) >= 0 && (
            <>
              <h3 className="nav__top-title">
                Admin
                {(location.pathname.indexOf(PAGE_URLS.ADMIN_USERS) >= 0 || location.pathname.indexOf(PAGE_URLS.ADMIN_ORGANISATIONS) >= 0) && (
                  <input type="text" className="form-control page-search" placeholder="search" onChange={onSearchChangeDebounce} />
                )}
              </h3>
              <div className="nav__top-links">
                {store?.user?.roleLevel <= 1 && <NavLink to={PAGE_URLS.ADMIN_DASHBOARD}>Dashboard</NavLink>}
                {store?.user?.roleLevel <= 1 && <NavLink to={PAGE_URLS.ADMIN_CONFIGURATION}>Global Configuration</NavLink>}
                <NavLink to={PAGE_URLS.ADMIN_ORGANISATIONS}>Organisations</NavLink>
                <NavLink to={PAGE_URLS.ADMIN_USERS}>Users</NavLink>
                <NavLink to={PAGE_URLS.ADMIN_APPROVALS}>Approvals</NavLink>
              </div>
            </>
          )}
          {location.pathname.indexOf(PAGE_URLS.EXCEL) >= 0 && (
            <>
              <h3 className="nav__top-title">
                Excel Connector
                {(location.pathname.indexOf(PAGE_URLS.EXCEL_AREAS) >= 0 || location.pathname.indexOf(PAGE_URLS.EXCEL_SUBAREAS) >= 0) && (
                  <input type="text" className="form-control page-search" placeholder="search" onChange={onSearchChangeDebounce} />
                )}
              </h3>
              <div className="nav__top-links">
                <NavLink to={PAGE_URLS.EXCEL_AREAS}>Areas</NavLink>
                <NavLink to={PAGE_URLS.EXCEL_SUBAREAS}>Subareas</NavLink>
                <NavLink to={PAGE_URLS.EXCEL_VERSIONS}>Versions</NavLink>
                <NavLink to={PAGE_URLS.EXCEL_HELPER_TABLES}>Helper Tables</NavLink>
                {store?.user?.roleLevel <= 2 && <NavLink to={PAGE_URLS.EXCEL_TABLE_SYNCHRONISATION}>Configure</NavLink>}
                <NavLink to={PAGE_URLS.EXCEL_SECURITY_GROUPS} className={location.pathname.indexOf('/excel/security') >= 0 ? 'active' : ''}>
                  Security
                </NavLink>
              </div>
              {/* {(location.pathname.indexOf(PAGE_URLS.EXCEL_SUBAREAS) >= 0 || location.pathname.indexOf(PAGE_URLS.EXCEL_AREAS) >= 0) && (
                <div className="nav__top-sublinks">
                  <NavLink to={PAGE_URLS.EXCEL_AREA_SUBAREAS}>Link</NavLink>
                </div>
              )} */}
              {location.pathname.indexOf('/excel/security') >= 0 && (
                <div className="nav__top-sublinks">
                  <NavLink to={PAGE_URLS.EXCEL_SECURITY_GROUPS}>Groups</NavLink>
                  <NavLink to={PAGE_URLS.EXCEL_USER_SECURITY_GROUPS}>User groups</NavLink>
                  <NavLink to={PAGE_URLS.EXCEL_USER_HELPER_TABLES}>User helper tables</NavLink>
                </div>
              )}
            </>
          )}
          {currentConnectorType && currentConnectorType !== 'excel' && (
            <>
              <h3 className="nav__top-title">
                {currentConnectorType?.replace('server', ' server')} Connectors
                <input type="text" className="form-control page-search" placeholder="search" onChange={onSearchChangeDebounce} />
              </h3>
              <div className="nav__top-links">
                <NavLink to={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_REFRESH.replace(':connectorType', currentConnectorType)}>Refresh</NavLink>
                {store?.user?.role?.toLowerCase().indexOf('admin') >= 0 && <NavLink to={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_MANAGE.replace(':connectorType', currentConnectorType)}>Manage</NavLink>}
                {currentConnectorType === 'xero' && store?.user?.role?.toLowerCase().indexOf('superadmin') >= 0 && (
                  <>
                    <NavLink to={PAGE_URLS.XERO_V2_SYNCHRONISE.replace(':connectorType', currentConnectorType)}>Refresh V2</NavLink>
                    <NavLink to={PAGE_URLS.XERO_V2_ASSIGN_USERS.replace(':connectorType', currentConnectorType)}>Configure V2</NavLink>
                  </>
                )}
              </div>
            </>
          )}
          {location.pathname.indexOf(PAGE_URLS.HOME) >= 0 && (
            <>
              <h3 className="nav__top-title">Home</h3>
            </>
          )}
        </nav>
        <div className="dashboard__content">{children}</div>
      </div>
    </div>
  );
};

export default withRouter(Nav);

import { useContext, MouseEvent, useState } from 'react';
import { Store } from '../store/Store';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { PAGE_URLS } from '../routes/routes';

import jwt from 'jwt-decode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import B2CClientConnector from '../interfaces/B2CClientConnector';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const Business2CloudConnectorManageCard = ({
  clientConnector,
  onProcessClientConnectorClick,
}: {
  clientConnector: B2CClientConnector;
  onProcessClientConnectorClick: (event: MouseEvent<HTMLButtonElement>, clientConnector: B2CClientConnector, fullTransactionSync: boolean) => Promise<void>;
}) => {
  const [searchParams] = useSearchParams();

  const { connectorType } = useParams();

  const storeContext = useContext(Store);
  const search = searchParams.get('search') as string;

  let currentRole = jwt<any>(storeContext?.store?.jwt)?.Role?.toLowerCase() ?? '';
  let manuallyTriggeredAtUtcCheck = clientConnector.Log?.ManuallyTriggeredAtUtc ? new Date(new Date(clientConnector.Log.ManuallyTriggeredAtUtc).getTime() + 1000 * 60 * 30) : new Date();

  let automaticallyTriggeredAtUtcCheck = clientConnector.Log?.AutomaticallyTriggeredAtUtc ? new Date(new Date(clientConnector.Log.AutomaticallyTriggeredAtUtc).getTime() + 1000 * 60 * 30) : new Date();

  let hasWarning =
    !clientConnector.Log?.CompletedAtUtc &&
    ((!!clientConnector.Log?.ManuallyTriggeredAtUtc && manuallyTriggeredAtUtcCheck < new Date()) ||
      (automaticallyTriggeredAtUtcCheck > manuallyTriggeredAtUtcCheck && !!clientConnector.Log?.AutomaticallyTriggeredAtUtc && automaticallyTriggeredAtUtcCheck < new Date()));

  let allowSynchronisation =
    !clientConnector.Log ||
    !!clientConnector.Log?.CompletedAtUtc ||
    (!!clientConnector.Log?.ManuallyTriggeredAtUtc && manuallyTriggeredAtUtcCheck < new Date()) ||
    (automaticallyTriggeredAtUtcCheck > manuallyTriggeredAtUtcCheck && !!clientConnector.Log?.AutomaticallyTriggeredAtUtc && automaticallyTriggeredAtUtcCheck < new Date());

  const renderStatusIcon = () => {
    if (clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED') return <></>;

    if (hasWarning && !clientConnector.Log?.HasError) return <span className={`material-symbols-rounded user-select-none warning`}>warning</span>;
    else if (!hasWarning && clientConnector.Log?.HasError) return <span className={`material-symbols-rounded user-select-none alert`}>error</span>;

    if (clientConnector.Log && !clientConnector.Log?.CompletedAtUtc) return <span className={`material-symbols-rounded user-select-none pending`}>schedule</span>;

    if (clientConnector.Log?.CompletedAtUtc) return <span className={`material-symbols-rounded user-select-none active`}>check_circle</span>;

    return <span className={`material-symbols-rounded user-select-none pending`}>pending</span>;
  };

  const renderStatusText = () => {
    if (clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED') return <></>;

    if (!clientConnector.Log?.CompletedAtUtc && !clientConnector.Log?.ManuallyTriggeredAtUtc)
      return (
        <small className="light-text">
          <div>Never started</div>
        </small>
      );

    return (
      <>
        <small className="light-text">
          {clientConnector.Log?.CompletedAtUtc ? 'Completed' : 'Started'} <b>{dayjs(clientConnector.Log?.CompletedAtUtc ?? clientConnector.Log?.ManuallyTriggeredAtUtc).fromNow()}</b>
        </small>
      </>
    );
  };

  const renderBody = () => {
    if (clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED') return '';

    if (clientConnector.Log?.Forbidden)
      return (
        <small className="d-flex flex-column gap-1 text-warning">
          <span>
            <b>This connector may have been disconnected.</b>
          </span>
          {currentRole.indexOf('admin') >= 0 && (
            <span>
              You may need to re-authorise or disconnect fully from the{' '}
              <Link to={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_MANAGE.replace(':connectorType', connectorType as string)}>connector configuration</Link> screen
            </span>
          )}
        </small>
      );

    if (hasWarning)
      return (
        <small className="d-flex flex-column gap-1 text-warning">
          <span>
            <b>This connector seems to be taking longer than expected.</b>
          </span>
          <span>{allowSynchronisation ? 'Please try refreshing it' : 'You will be able to retry in few minutes'}</span>
        </small>
      );
  };

  const filter = !search || clientConnector.Name.toLowerCase().indexOf(search) >= 0 || clientConnector.Name.toLowerCase().indexOf(search) >= 0;

  if (!filter) return <></>;

  return (
    <div
      className={`tw-shadow tw-transition-all hover:tw-shadow-lg hover:tw-ring tw-ring-offset-1 tw-ring-blue-500 tw-no-underline tw-font-semibold !tw-text-slate-900 card card-sm  ${
        clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED' ? 'disabled' : ''
      }`}
      style={{ height: 230 }}>
      <div className="card-header align-items-start">
        <div style={{ maxWidth: '80%' }}>
          <div className="d-flex flex-column gap-3">
            <span className="text-uppercase fw-bold">
              {(clientConnector.Status === 'DELETED' && (
                <span className="p-1 px-2 fs-11 text-danger bg-danger bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                  deleted
                </span>
              )) ||
                (clientConnector.Status === 'DISCONNECTED' && (
                  <span className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                    disconnected
                  </span>
                )) ||
                (clientConnector.Log?.Forbidden && (
                  <span className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                    unkown
                  </span>
                )) ||
                (clientConnector.Status !== 'DISCONNECTED' && (
                  <span className="p-1 px-2 fs-11 text-success bg-success bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                    connected
                  </span>
                ))}
            </span>
            <span className="d-flex flex-column">
              {clientConnector.Name !== clientConnector.OriginalName && <small className="fw-bold lighter-text fs-11">{clientConnector.OriginalName}</small>}
              <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>{clientConnector.Name}</span>
              {renderStatusText()}
            </span>
          </div>
        </div>
        <span className="ms-auto">{renderStatusIcon()}</span>
      </div>
      <div className="card-body">{renderBody()}</div>
      <div className="card-footer gap-2 mt-auto">
        {clientConnector.Status !== 'DISCONNECTED' && clientConnector.Status !== 'DELETED' && (
          <div className="flex flex-row gap-2">
            <button className="btn btn-sm btn-primary justify-content-center" disabled={!allowSynchronisation} onClick={(event) => onProcessClientConnectorClick(event, clientConnector, false)}>
              {clientConnector.Log?.Forbidden ? 'Retry' : currentRole.indexOf('superadmin') > -1 ? 'Incremental' : 'Refresh'}
            </button>
            {!clientConnector.Log?.Forbidden && currentRole.indexOf('superadmin') > -1 && (
              <button
                className="btn btn-sm btn-outline-primary justify-content-center ms-auto"
                disabled={!allowSynchronisation}
                onClick={(event) => onProcessClientConnectorClick(event, clientConnector, true)}>
                Full
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Business2CloudConnectorManageCard;

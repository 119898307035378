const UserCard = ({ user, onEditClick, onDeleteClick }: { user: any; onEditClick: () => void; onDeleteClick: () => void }) => {
  const renderStatusIcon = () => {
    if (user.Status?.Name === 'locked')
      return (
        <span className={`material-symbols-rounded user-select-none warning`} title='Locked'>
          lock
        </span>
      );

    if (user.Status?.Name === 'archived')
      return (
        <span className={`material-symbols-rounded user-select-none pending`} title='Archived'>
          archive
        </span>
      );

    return (
      <span className={`material-symbols-rounded user-select-none active`} title='Active'>
        check_circle
      </span>
    );
  };

  return (
    <div className='card card-sm card-interactive grid-item' style={{ height: 150 }}>
      <div className='card-header align-items-start'>
        <div>
          {user.FirstName} {user.LastName}
          <small className='light-text d-block'>{user.Role?.Name}</small>
        </div>
        <div className='ms-auto'>{renderStatusIcon()}</div>
      </div>
      <div className='card-footer gap-2 mt-auto'>
        <div className='d-flex flex-row gap-1'>
          <button className='btn btn-sm btn-primary me-auto' onClick={onEditClick}>
            Edit
          </button>
          <button className='btn btn-sm btn-round btn-danger' title='Delete' onClick={onDeleteClick}>
            <span className='material-symbols-rounded'>delete</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserCard;

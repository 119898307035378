import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

// todo remove this comment testing deployment

const api = ApiService.getInstance();

const STATUS_TRANSLATE = {
  active: 'active',
  locked: 'lock',
  archived: 'archive',
  lock: 'locked',
  archive: 'archived',
};

const HelperTables = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const deleteEnabled = store.user && store.user.role && store.user.role.toLowerCase() === 'superadmin' ? true : false;

  const [helperTables, setHelperTables] = React.useState([]);
  const [newTable, setNewTable] = React.useState('');
  const [values, setValues] = React.useState<any>({});
  const [modalShowAdd, setModalShowAdd] = React.useState<any>(false);
  const [modalShowEdit, setModalShowEdit] = React.useState<any>(false);
  const [sortListHelperTableMainDirection, setSortListHelperTableMainDirection] = React.useState<SORT_DIRECTION>(SORT_DIRECTION.ASC);

  const getHelperTables = async () => {
    let helperTablesFetched = await api.getHelperTables(store?.organisationSelected?.organisationId);
    if (helperTablesFetched) {

      // sort helper table
      helperTablesFetched = sortList(helperTablesFetched, ['Name'], SORT_DIRECTION.ASC);
      setSortListHelperTableMainDirection(SORT_DIRECTION.ASC);

      setHelperTables(helperTablesFetched);
      const storeAction = {
        type: REDUCER_ACTION_SET.SET_HELPER_TABLES,
        payload: helperTablesFetched,
      };
      dispatch(storeAction);
    }
  };

  const getStatuses = async () => {
    const statusesFetched = await api.getStatuses();
    if (statusesFetched && Array.isArray(statusesFetched)) {
      const statusOptions: any = {};
      statusesFetched.forEach((status) => {
        if (status.Name && status.Id) {
          statusOptions[status.Name] = status;
        }
      });
      const newValues = { ...values };
      newValues['statusOptions'] = statusOptions;
      setValues(newValues);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getHelperTables();
      getStatuses();

      dispatch({
        type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
        payload: 'Helper Tables',
      });
    })();
  }, [store?.organisationSelected?.organisationId]);

  const handleChange = (e: any, name: string) => {
    const newValues = { ...values };

    newValues[name] = e.target.value;

    setValues(newValues);
  };

  const editPopup = async (helperTable: any) => {
    const newValues = { ...values };

    newValues.editHelperTable = helperTable;
    //newValues.editFlag = false;
    newValues.editName = helperTable.Name;
    newValues.errors = null;
    newValues.status =
      helperTable.Status && helperTable.Status.Name && STATUS_TRANSLATE[(helperTable.Status.Name as 'active') || 'locked' || 'archived']
        ? STATUS_TRANSLATE[(helperTable.Status.Name as 'active') || 'locked' || 'archived']
        : STATUS_TRANSLATE['active'];

    //
    setValues(newValues);
    setModalShowEdit(true);
  };

  const addPopup = async () => {
    const newValues = { ...values };

    newValues.tables = '';
    newValues.errors = null;
    setValues(newValues);

    setModalShowAdd(true);
  };

  const addTables = async () => {
    const tables = values.tables.split('\n');

    let failedItems = '';
    const errors = [];
    for (let i = 0; i < tables.length; i++) {
      // const table = tables[0].split(',');
      const table = tables[i].trim();
      //const name = table.length > 0 ? table[0] : '';
      const name = table;
      //const inputTableName = table.length > 1 ? table[1] : '';
      let inputTableName = name.replace(/[^a-zA-Z0-9]/g, '');
      inputTableName = inputTableName.length > 0 && !isNaN(inputTableName[0]) ? inputTableName.substring(1) : inputTableName;
      const result = await api.addHelperTable(name, inputTableName, s.store?.organisationSelected?.organisationId); // TODO handle multiple orgs
      if (result && result.statusCode) {
        failedItems = failedItems !== '' ? failedItems + '\n' : failedItems;
        failedItems += name;
        result.message && errors.push(result.message);
      }
    }

    if (failedItems !== '') {
      const newValues = { ...values };
      newValues['tables'] = failedItems;
      newValues['errors'] = errors;

      setValues(newValues);
    } else {
      getHelperTables();
      setModalShowAdd(false);
    }
  };

  const editTable = async (newName: string, editHelperTable: any) => {
    const statusId =
      values.status &&
      STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive'] &&
      values.statusOptions &&
      values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']] &&
      values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
        ? values.statusOptions[STATUS_TRANSLATE[(values.status as 'active') || 'lock' || 'archive']].Id
        : null;

    //const name = table.length > 0 ? table[0] : '';
    const name = newName.trim();
    //const inputTableName = table.length > 1 ? table[1] : '';
    let inputTableName = name.replace(/[^a-zA-Z0-9]/g, '');
    inputTableName = inputTableName.length > 0 && !isNaN(inputTableName[0] as any) ? inputTableName.substring(1) : inputTableName;
    const result = await api.editHelperTable(name, inputTableName, s.store?.organisationSelected?.organisationId, editHelperTable.Id, statusId); // TODO handle multiple orgs

    let failedItems = '';
    const errors = [];
    if (result && result.statusCode) {
      failedItems = failedItems !== '' ? failedItems + '\n' : failedItems;
      failedItems += newName;
      result.message && errors.push(result.message);
    }

    if (failedItems !== '') {
      const newValues = { ...values };
      newValues['errors'] = errors;
      setValues(newValues);
    } else {
      getHelperTables();
      setModalShowEdit(false);
    }
  };

  const deleteTable = async (editHelperTableId: string) => {
    const result = await api.deleteHelperTable(editHelperTableId); // TODO handle multiple orgs
    getHelperTables();
    //setModalShowEdit(false);
  };

  const sortListHelperTable= (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(helperTables, ['Name'], sortDirection);

    setSortListHelperTableMainDirection(sortDirection);
    setHelperTables(sortedList as any);
  };

  return (
    <div className='row justify-content-center'>
      <div className='col-11'>
        <h3 className='page-heading has-tools mb-4'>
          Helper Tables
          <div className='tools'>
            <button
              className='btn btn-secondary fw-bold'
              onClick={() => {
                addPopup();
              }}>
              <i className='fa-solid fa-plus' />
              Add new Helper Table
            </button>
          </div>
        </h3>
        <div className='card card--height-limit'>
          <table className='table table--no-head-border'>
            <colgroup>
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr className='table-header'>
              <th style={{cursor: 'pointer'}}>
                <div style={{display: 'inline-block'}}
                  onClick={(e) => {
                    sortListHelperTable(sortListHelperTableMainDirection === undefined || sortListHelperTableMainDirection === SORT_DIRECTION.ASC
                      ? SORT_DIRECTION.DEC 
                      : SORT_DIRECTION.ASC
                    );
                }}> 
                  Name
                  <i className={sortListHelperTableMainDirection === undefined || sortListHelperTableMainDirection === SORT_DIRECTION.ASC 
                    ? 'fa-solid fa-arrow-down-short-wide ms-1'
                    : 'fa-solid fa-arrow-up-wide-short ms-1'
                  } />
                </div>
                <div style={{paddingLeft: '10px', display: 'inline-block'}}>
                  <input
                    className='form-control form-control-sm'
                    type='text'
                    id='filterHelperTable'
                    name='filterHelperTable'
                    placeholder='Search'
                    value={values.filterHelperTable}
                    onChange={(e) => handleChange(e, 'filterHelperTable')}
                  />
                </div> 
                </th>
                <th>Excel Table Name</th>
                <th>SQL Table Name</th>
                <th>Status</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {helperTables.map((helperTable: any, index) => {
                if (values.filterHelperTable && values.filterHelperTable !== '' && helperTable.Name.toLowerCase().indexOf(values.filterHelperTable.toLowerCase()) === -1) {
                  return <Fragment></Fragment>;
                }
                return (
                  <tr key={index}>
                    <td>
                      <span>{helperTable.Name}</span>
                    </td>
                    <td>
                      <span>{helperTable.InputTableName}</span>
                    </td>
                    <td>
                      <span>B2S{helperTable.InputTableName.trim()}</span>
                    </td>
                    <td>
                      <div>
                        {(() => {
                          if (helperTable.Status && helperTable.Status.Name === 'active') {
                            return <span className='status-label active'>ACTIVE</span>;
                          } else if (helperTable.Status && helperTable.Status.Name === 'locked') {
                            return <span className='status-label locked'>LOCKED</span>;
                          } else if (helperTable.Status && helperTable.Status.Name === 'archived') {
                            return <span className='status-label archived'>ARCHIVED</span>;
                          } else {
                            return <span className='status-label active'>ACTIVE</span>;
                          }
                        })()}
                      </div>
                    </td>
                    <td>
                      <div className='tools'>
                        <button
                          className='btn btn-outline-primary btn-sm'
                          onClick={() => {
                            editPopup(helperTable);
                          }}>
                          <i className='fa-solid fa-pen-to-square' />
                          Edit
                        </button>
                        {deleteEnabled && (
                          <button
                            className='btn btn-outline-danger btn-sm'
                            onClick={() => {
                              deleteTable(helperTable.Id);
                            }}>
                            <i className='fa-solid fa-trash-can' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
              <td colSpan={5}>
                <div className="tools align-items-end">
            <textarea
              onChange={(e) => handleChange(e, 'tables')}
              placeholder="name, input-table-name"
              className="form-control form-control-custom"
            />

                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => {
                      addTables();
                    }}><i className="fa-solid fa-check" />Add</button>
                </div>
              </td>
            </tr> */}
            </tbody>
          </table>
        </div>

        {modalShowAdd && !modalShowEdit && (
          <div>
            <div className='modal d-block'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content' style={{ zIndex: 1051 }}>
                  <div className='modal-header'>
                    <h5 className='modal-title'>{values.editUserFlag ? 'Edit Area' : 'Add new Helper Table'}</h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        setModalShowAdd(false);
                      }}
                    />
                  </div>
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='milestoneName' className='label--has-info'>
                        Name(s)
                        <small className='alert alert-success p-2'>
                          <i className='fa-solid fa-circle-info me-2' />
                          Add multiple items by separating by a new line
                        </small>
                      </label>
                      <textarea onChange={(e) => handleChange(e, 'tables')} placeholder='name' className='form-control' value={values.tables} />
                      {values.errors && Array.isArray(values.errors) && values.errors.length > 0 && (
                        <div className='mt-2'>
                          <label className='label--has-info'>
                            <small className='alert alert-danger p-2'>
                              <i className='fa-solid fa-exclamation-circle me-2' />
                              <div className='float-end'>
                                {values.errors.map((message: any) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            </small>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      className='btn btn-success'
                      onClick={() => {
                        addTables();
                      }}>
                      <i className='fa-solid fa-check' />
                      Add
                    </button>

                    <button
                      className='btn btn-outline-dark'
                      onClick={() => {
                        setModalShowAdd(false);
                      }}>
                      <i className='fa-solid fa-xmark' />
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className='modal-backdrop fade show' />
            </div>
          </div>
        )}

        {!modalShowAdd && modalShowEdit && (
          <div>
            <div className='modal d-block'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content' style={{ zIndex: 1051 }}>
                  <div className='modal-header'>
                    <h5 className='modal-title'>Edit Helper Table</h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        setModalShowEdit(false);
                      }}
                    />
                  </div>
                  <div className='modal-body'>
                    <div className='mb-3'>
                      <label htmlFor='editName'>Name</label>
                      <input
                        className='form-control'
                        type='text'
                        id='editName'
                        name='editName'
                        placeholder='Add Area'
                        value={values.editName}
                        onChange={(e) => handleChange(e, 'editName')}
                      />
                      {values.errors && Array.isArray(values.errors) && values.errors.length > 0 && (
                        <div className='mt-2'>
                          <label className='label--has-info'>
                            <small className='alert alert-danger p-2'>
                              <i className='fa-solid fa-exclamation-circle me-2' />
                              <div className='float-end'>
                                {values.errors.map((message: any) => {
                                  return <div>{message}</div>;
                                })}
                              </div>
                            </small>
                          </label>
                        </div>
                      )}
                      <label className='mt-2'>Set Status</label>
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id='active'
                          name='status'
                          className='form-check-input'
                          value={'active'}
                          checked={values.status === 'active'}
                          onChange={(e) => {
                            handleChange(e, 'status');
                          }}
                        />
                        <label className='form-check-label' htmlFor={'active'}>
                          Active
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id='lock'
                          name='status'
                          className='form-check-input'
                          value={'lock'}
                          checked={values.status === 'lock'}
                          onChange={(e) => {
                            handleChange(e, 'status');
                          }}
                        />
                        <label className='form-check-label' htmlFor={'lock'}>
                          Lock
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          autoComplete='off'
                          type='radio'
                          id='archive'
                          name='status'
                          className='form-check-input'
                          value={'archive'}
                          checked={values.status === 'archive'}
                          onChange={(e) => {
                            handleChange(e, 'status');
                          }}
                        />
                        <label className='form-check-label' htmlFor={'archive'}>
                          Archive
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      className='btn btn-success'
                      onClick={() => {
                        editTable(values.editName, values.editHelperTable);
                      }}>
                      <i className='fa-solid fa-check' />
                      Apply
                    </button>

                    <button
                      className='btn btn-outline-dark'
                      onClick={() => {
                        setModalShowEdit(false);
                      }}>
                      <i className='fa-solid fa-xmark' />
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className='modal-backdrop fade show' />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(HelperTables);

export enum PAGE_URLS {
  ROOT = '/',
  SIGNUP = '/signup',
  SIGNUP_FINALISE = '/signup/finalise',
  SIGNIN = '/signin',
  SIGNIN_CONFIRM = '/signin/confirm',
  HOME = '/home',
  PROFILE = '/profile',
  RESET_PASSWORD = '/reset-password',
  RESET_PASSWORD_VIEW = '/reset-password/:resetPasswordRedeemToken',
  RESET_PASSWORD_REDEEM = '/reset-password/redeem/:resetPasswordRedeemToken',
  SET_PASSWORD = '/set-password/redeem/:resetPasswordRedeemToken',

  ADMIN = '/admin',
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_CONFIGURATION = '/admin/configuration',
  ADMIN_USERS = '/admin/security/users',
  ADMIN_APPROVALS = '/admin/approvals',
  ADMIN_APPROVAL = '/admin/approvals/:approvalId',
  ADMIN_ORGANISATIONS = '/admin/organisations',
  ADMIN_ORGANISATION = '/admin/organisations/:organisationId',

  EXCEL = '/excel',
  EXCEL_ENTITIES = '/excel/security/entities',
  EXCEL_SECURITY_GROUPS = '/excel/security/security-groups',
  EXCEL_USER_SECURITY_GROUPS = '/excel/security/user-security-groups',
  EXCEL_USER_ENTITIES = '/excel/user-entities',
  EXCEL_ENTITY_USERS = '/excel/entity-users',
  EXCEL_USER_HELPER_TABLES = '/excel/security/user-helper-tables',
  EXCEL_HELPER_TABLE_USERS = '/excel/helper-table-users',
  EXCEL_TABLE_SYNCHRONISATION = '/excel/table-synchronisation',
  EXCEL_AREAS = '/excel/areas',
  EXCEL_SUBAREAS = '/excel/subareas',
  EXCEL_AREA_SUBAREAS = '/excel/subareas/link',
  EXCEL_SUBAREA_AREAS = '/excel/subarea-areas',
  EXCEL_VERSIONS = '/excel/versions',
  EXCEL_HELPER_TABLES = '/excel/helper-tables',

  XERO_V2_TOKENS = '/connectors/:connectorType/v2/tokens',
  XERO_V2_SYNCHRONISE = '/connectors/:connectorType/v2/synchronise',
  XERO_V2_ASSIGN_USERS = '/connectors/:connectorType/v2/assign-users',

  BUSINESS_2_CLOUD_CONNECTORS = '/connectors',
  BUSINESS_2_CLOUD_CONNECTOR_REFRESH = '/connectors/:connectorType/refresh',
  BUSINESS_2_CLOUD_CONNECTOR_MANAGE = '/connectors/:connectorType/manage',
}

const B2CColors = {
  Providers: (provider: string) =>
    provider
      ? {
          aws: { background: 'tw-bg-aws', ring: 'tw-ring-aws' },
          cin7: { background: 'tw-bg-cin7', ring: 'tw-ring-cin7' },
          excel: { background: 'tw-bg-excel', ring: 'tw-ring-excel' },
          gaap: { background: 'tw-bg-gaap', ring: 'tw-ring-gaap' },
          ftp: { background: 'tw-bg-ftp', ring: 'tw-ring-ftp' },
          mailchimp: { background: 'tw-bg-mailchimp', ring: 'tw-ring-mailchimp' },
          sqlserver: { background: 'tw-bg-sqlserver', ring: 'tw-ring-sqlserver' },
          uniconta: { background: 'tw-bg-uniconta', ring: 'tw-ring-uniconta' },
          vend: { background: 'tw-bg-vend', ring: 'tw-ring-vend' },
          woocommerce: { background: 'tw-bg-woocommerce', ring: 'tw-ring-woocommerce' },
          xero: { background: 'tw-bg-xero', ring: 'tw-ring-xero' },
        }[provider.toLowerCase()]
      : { background: '', ring: '' },
};

export default B2CColors;

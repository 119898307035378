/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, KeyboardEvent, createRef, useContext, useEffect, useState } from 'react';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { useSearchParams } from 'react-router-dom';
import { REDUCER_ACTION_SET } from '../store/types';
import { Store } from '../store/Store';
import { PAGE_URLS } from '../routes/routes';

import FormSection from './FormSection';
import Form from './Form';
import FormInput from './FormInput';

const SigninConfirm = (props: any) => {
  const formRef = createRef<any>();
  const store = useContext(Store);

  const [searchParams] = useSearchParams();
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationTrustDevice, setVerificationTrustDevice] = useState(false);
  const [error, setError] = useState('');
  const [errorTop, setErrorTop] = useState(0);
  const [errorSticky, setErrorSticky] = useState(false);
  const [hideError, setHideError] = useState(false);
  const [showLoginLoader, setShowLoginLoader] = useState(false);

  const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  useEffect(() => {
    if (!searchParams.get('reference')) ApiService.logout();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  useEffect(() => setErrorSticky(errorTop < 0), [errorTop]);

  const onVerificationCodeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setVerificationCode(event.currentTarget.value);
  };

  const onTrustThisDeviceInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVerificationTrustDevice(event.currentTarget.checked);
  };

  const onInputKeyUp = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && verificationCode.length) {
      await login();
    }
  };

  const login = async () => {
    try {
      setShowLoginLoader(true);
      setError('');

      const verificationReference = searchParams.get('reference') as string;
      const resJson: any = await ApiService.getInstance().loginWithVerificationCode(verificationReference, verificationCode, verificationTrustDevice);

      if (resJson?.error) {
        setError(resJson?.message);
        setShowLoginLoader(false);
        return;
      }

      if (!resJson?.accessToken || (resJson.statusCode && resJson.statusCode === 401)) {
        setError('Verification failed.');
        setShowLoginLoader(false);
        return;
      }

      ApiService.getInstance().setJwt(resJson.accessToken);

      store.dispatch({
        type: REDUCER_ACTION_SET.SET_JWT_TOKEN,
        payload: resJson.accessToken,
      });

      store.dispatch({
        type: REDUCER_ACTION_SET.SET_USER,
        payload: {
          firstName: resJson.firstName,
          lastName: resJson.lastName,
          role: resJson.role,
          roleLevel: resJson.roleLevel,
          email: resJson.email,
          requireMultiFactorAuthentication: resJson.requireMultiFactorAuthentication,
          verificationDevices: resJson.verificationDevices,
        },
      });

      document.cookie = 'logged_in=true';
      document.cookie = `logged_in_name=${resJson.firstName}`;

      const returnUrl = searchParams.get('returnUrl');

      if (!!returnUrl) {
        props.router.navigate(decodeURIComponent(returnUrl));
      } else {
        props.router.navigate(PAGE_URLS.HOME);
      }
    } catch (err) {
      setError('An unkown error has occured.');
      setShowLoginLoader(false);
    }
  };

  return (
    <div className='login-container container'>
      <div className='row justify-content-center'>
        <div className='col-11 col-sm-10 col-md-7 col-lg-5 col-xl-4'>
          <div className='m-4 mt-5'>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
              <span className='text-center mt-2 text-nowrap light-text'>
                Verify your <b>Business2Cloud</b> login
              </span>
            </div>
          </div>
          <div className='card card-sm'>
            <div className='card-body' ref={formRef}>
              <div className='form'>
                {error && (
                  <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: error }}></div>
                      <i className='material-symbols-rounded' onClick={onErrorHideClick}>
                        cancel
                      </i>
                    </span>
                  </div>
                )}
                <div>
                  <Form onSubmit={() => login()}>
                    <FormSection>
                      <div className='form-section-header'>
                        <h5>Verification code</h5>
                        <span>Provide the verification code that was sent to your registered email address to continue your login.</span>
                      </div>
                      <FormInput
                        id='verificationCode'
                        placeholder='Verification code'
                        required={true}
                        value={verificationCode}
                        onChange={onVerificationCodeInputChange}
                        onKeyUp={onInputKeyUp}
                      />
                      <FormInput
                        type='checkbox'
                        id='trustThisDevice'
                        placeholder='Trust this device?'
                        value={verificationTrustDevice}
                        onChange={onTrustThisDeviceInputChange}
                      />
                      <button className='btn btn-sm btn-secondary me-auto mt-3' type='submit'>
                        Sign in
                      </button>
                    </FormSection>
                  </Form>
                </div>
              </div>
            </div>
            {showLoginLoader && (
              <div className='card-loader'>
                <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                  <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SigninConfirm);

import { ChangeEvent, createRef, useContext, useEffect, useLayoutEffect, useState, Fragment } from 'react';
import { Store } from '../store/Store';
import { withRouter } from '../routes/withRouter';
import { ApiService } from '../services/apiService';
import { useSearchParams } from 'react-router-dom';
import { capitalize, isArray } from 'lodash';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

import AreaCard from './AreaCard';
import Form from './Form';
import FormSection from './FormSection';
import FormInput from './FormInput';
import pluralize from 'pluralize';

const colors = require('ac-colors');

const Areas = () => {
  const store = useContext(Store).store;
  const containerRef = createRef<any>();

  const [searchParams] = useSearchParams();
  const [areaList, setAreaList] = useState<any[]>([]);
  const [subAreaList, setSubAreaList] = useState<any[]>([]);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalLoader, setShowModalLoader] = useState(false);
  const [showManageSubAreasModal, setShowManageSubAreasModal] = useState(false);
  const [showManageSubAreasModalSaveButton, setShowManageSubAreasModelSaveButton] = useState(false);
  const [subAreaSearchString, setSubAreaSearchString] = useState<string>('');
  const [selectedArea, setSelectedArea] = useState<any>();
  const [selectedSubAreaList, setSelectedSubAreaList] = useState<any[]>([]);
  const [additionalAreas, setAdditionalAreas] = useState<string[]>([]);
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState(false);
  const [error, setError] = useState<any>('');
  const [hideError, setHideError] = useState(false);

  const search = searchParams.get('search') as string;

  const onScroll = () => containerRef?.current;

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  const loadAreas = (callback?: () => void) => {
    setShowLoader(true);

    const promises = [ApiService.getInstance().getAreas(store?.organisationSelected?.organisationId), ApiService.getInstance().getSubAreas(store?.organisationSelected?.organisationId)];

    Promise.all(promises).then((responses) => {
      const areasSorted = sortList(responses[0], ['Name'], SORT_DIRECTION.ASC);
      setAreaList(areasSorted);
      setSubAreaList(responses[1]);

      if (callback) callback();

      setShowLoader(false);
    });
  };

  const loadStatuses = (callback?: () => void) => {
    ApiService.getInstance()
      .getStatuses()
      .then((response) => {
        setStatusList(response);

        if (callback) callback();
      });
  };

  const onAreaChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    setSelectedArea({
      ...selectedArea,
      [(event.currentTarget || event.target).id]: (event.currentTarget || event.target).value,
    } as any);

    setShowManageSubAreasModelSaveButton(true);
  };

  const onAdditionalAreaChange = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedAdditionalAreas = [...additionalAreas];

    updatedAdditionalAreas[parseInt(event.currentTarget.id)] = event.currentTarget.value;

    setAdditionalAreas(updatedAdditionalAreas);
  };

  const onAddClick = () => {
    setSelectedArea(undefined);
    setShowModal(true);
  };

  const onEditClick = (area: any) => {
    setSelectedArea(area);
    setShowModal(true);
  };

  const onDeleteClick = (area: any) => {
    setSelectedArea(area);
    setShowDeletionConfirmationModal(true);
  };

  const onManageSubAreasClick = (area: any) => {
    setSelectedArea(area);
    setSelectedSubAreaList([...area.Subareas]);
    setShowManageSubAreasModal(true);
  };

  const onAddAdditionalAreaClick = () => {
    setAdditionalAreas([...additionalAreas, '']);
  };

  const onRemoveAdditionalAreaClick = (index: number) => {
    let updatedAdditionalAreas = [...additionalAreas];

    updatedAdditionalAreas.splice(index, 1);

    setAdditionalAreas(updatedAdditionalAreas);
  };

  const onModalCancelClick = () => {
    setShowModal(false);
    setSelectedArea(undefined);
    setAdditionalAreas([]);
    setShowModalLoader(false);
    setShowManageSubAreasModal(false);
    setSubAreaSearchString('');
    setSelectedSubAreaList([]);
  };

  const onModalSaveClick = () => {
    setShowModalLoader(true);

    if (selectedArea.Id) {
      ApiService.getInstance()
        .updateArea(selectedArea.Name, store?.organisationSelected?.organisationId, selectedArea.Id, selectedArea.StatusId, selectedArea.SubareaLinkType)
        .then((response) => {
          console.log('1.1');
          if (Math.floor(response.status / 200) !== 2) {
            console.log('1.2');
            if (response.message) {
              console.log('1.3');
              if (isArray(response.message))
                setError(
                  <div>
                    <b>The following errors occurred:</b>
                    <ul style={{ margin: 0 }}>
                      {response.message.map((_: any) => (
                        <li>{_}</li>
                      ))}
                    </ul>
                  </div>
                );
              else setError(<div>{response.message}</div>);
            } else if (response.error) {
              setError(response.error);
            }
          } else {
            console.log('1');
            loadAreas(() => {
              setShowModal(false);
              setSelectedArea(undefined);
              setShowModalLoader(false);
            });
          }
        });
    } else {
      let promises = [];

      console.log('2.1');

      promises.push(ApiService.getInstance().createArea(selectedArea.Name, store?.organisationSelected?.organisationId));

      for (let additionalArea of additionalAreas) {
        promises.push(ApiService.getInstance().createArea(additionalArea, store?.organisationSelected?.organisationId));
      }

      Promise.all(promises).then((responses) => {
        debugger;
        let errors = [] as any[];

        for (const response of responses) {
          if (Math.floor(response.status / 200) !== 2) {
            if (response.message) {
              if (isArray(response.message)) errors = [...errors, ...response.message];
              else errors = [...errors, response.message];
            } else if (response.error) {
              errors = [...errors, ...response.error];
            }
          }
        }

        if (errors.length) {
          console.log('2.2');
          setError(
            <div>
              <b>The following errors occurred:</b>
              <ul style={{ margin: 0 }}>
                {errors.map((_: any) => (
                  <li>{_}</li>
                ))}
              </ul>
            </div>
          );
        } else {
          loadAreas(() => {
            console.log('2.3');
            setShowModal(false);
            setSelectedArea(undefined);
            setAdditionalAreas([]);
          });
        }

        setShowModalLoader(false);
      });
    }
  };

  const onManageSubAreasModalSaveClick = () => {
    setShowModalLoader(true);
    ApiService.getInstance()
      .updateArea(selectedArea.Name, store?.organisationSelected?.organisationId, selectedArea.Id, selectedArea.StatusId, selectedArea.SubareaLinkType)
      .then((response) => {
        if (response.error) {
          setError(response.error);
          return;
        }

        ApiService.getInstance()
          .unlinkAreaWithSubAreaListAll(selectedArea.Id)
          .then(() => {
            if (selectedSubAreaList.length) {
              ApiService.getInstance()
                .linkAreaWithSubAreaList(
                  selectedArea.Id,
                  selectedSubAreaList.map((_) => _.Id)
                )
                .then(() => {
                  loadAreas(() => {
                    setShowManageSubAreasModal(false);
                    setSelectedArea(undefined);
                    setAdditionalAreas([]);
                    setShowModalLoader(false);
                  });
                });
            } else {
              loadAreas(() => {
                setShowManageSubAreasModal(false);
                setSelectedArea(undefined);
                setAdditionalAreas([]);
                setShowModalLoader(false);
              });
            }
          });
      });
  };

  const onManageSubAreasModalSelectAllClick = () => {
    setSelectedSubAreaList([...subAreaList]);
    setShowManageSubAreasModelSaveButton(true);
  };

  const onManageSubAreasModalSelectNoneClick = () => {
    setSelectedSubAreaList([]);
    setShowManageSubAreasModelSaveButton(true);
  };

  const onConfirmDeleteClick = () => {
    ApiService.getInstance()
      .deleteArea(selectedArea.Name, store?.organisationSelected?.organisationId, selectedArea.Id)
      .then((response) => {
        if (Math.floor(response.status / 200) !== 2) {
          if (response.message) {
            if (isArray(response.message))
              setError(
                <div>
                  <b>The following errors occurred:</b>
                  <ul style={{ margin: 0 }}>
                    {response.message.map((_: any) => (
                      <li>{_}</li>
                    ))}
                  </ul>
                </div>
              );
            else setError(<div>{response.message}</div>);
          } else if (response.error) {
            setError(response.error);
          }
        } else {
          loadAreas(() => {
            setShowDeletionConfirmationModal(false);
          });
        }
      });
  };

  const onSelectSubAreaChanged = (event: ChangeEvent<HTMLInputElement>, aubarea: any) => {
    let tempSelectedUserList = [...selectedSubAreaList];

    if (event.target.checked) {
      tempSelectedUserList.push(aubarea);
    } else {
      let userIndex = tempSelectedUserList.findIndex((_) => _.Id === aubarea.Id);
      tempSelectedUserList.splice(userIndex, 1);
    }

    setShowManageSubAreasModelSaveButton(true);
    setSelectedSubAreaList(tempSelectedUserList);
  };

  const onSubAreaSearchChange = (event: ChangeEvent<HTMLInputElement>) => setSubAreaSearchString(event.target.value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => loadStatuses(loadAreas), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadAreas(), [store?.organisationSelected?.organisationId]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <>
      {error && (
        <div className={`error-message sticky ${hideError ? 'hide' : ''}`}>
          <span>
            {error}
            <i className="material-symbols-rounded" onClick={onErrorHideClick}>
              cancel
            </i>
          </span>
        </div>
      )}
      {showDeletionConfirmationModal && (
        <div className="modal modal-sm">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Form>
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Delete</h5>
                      <span>
                        Are you sure you want to delete <b>{selectedArea?.Name}</b>?
                      </span>
                    </div>
                  </FormSection>
                  <FormSection>
                    <div className="d-flex flex-row gap-2 justify-content-end">
                      <button className="btn btn-sm btn-outline-secondary" onClick={() => setShowDeletionConfirmationModal(false)}>
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-sm btn-danger" onClick={onConfirmDeleteClick}>
                        Delete
                      </button>
                    </div>
                  </FormSection>
                </Form>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      {showModal && (
        <Form onSubmit={() => {}}>
          <div className="modal modal-sm">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title">{additionalAreas.length > 0 ? 'Multiple areas' : selectedArea?.Name || 'New area'}</h5>
                    <span className="light-text">Area properties</span>
                  </div>
                </div>
                <div className="modal-body">
                  <FormSection>
                    <FormInput
                      id="Name"
                      name="Name"
                      label={pluralize('Name', additionalAreas.length + 1)}
                      autoComplete="off"
                      required={true}
                      value={selectedArea?.Name}
                      placeholder="Name"
                      onChange={onAreaChange}
                    />
                    {additionalAreas.map((additionalArea, index) => (
                      <div key={index} className="flex gap-2 align-items-center">
                        <FormInput className="flex-grow-1" id={index} name="Name" autoComplete="off" required={true} value={additionalArea} placeholder="Name" onChange={onAdditionalAreaChange} />
                        <button className="btn btn-sm btn-round btn-secondary btn-danger" title="Remove" onClick={() => onRemoveAdditionalAreaClick(index)}>
                          <span className="material-symbols-rounded">remove</span>
                        </button>
                      </div>
                    ))}
                    {!selectedArea?.Id && (
                      <button className="btn btn-sm btn-primary justify-content-center" onClick={onAddAdditionalAreaClick}>
                        <span className="material-symbols-rounded m-auto">add_circle</span>
                      </button>
                    )}
                    {selectedArea?.Id && (
                      <FormInput
                        id="StatusId"
                        name="StatusId"
                        label="Status"
                        type="select"
                        items={statusList.map((_) => ({ key: _.Id, value: capitalize(_.Name) }))}
                        autoComplete="off"
                        value={selectedArea?.Status?.Id}
                        placeholder="Status"
                        onChange={onAreaChange}
                      />
                    )}
                  </FormSection>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-sm btn-outline-dark" onClick={onModalCancelClick}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary" onClick={onModalSaveClick}>
                    Save
                  </button>
                </div>
                {showModalLoader && (
                  <div className="modal-loader">
                    <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        </Form>
      )}
      {showManageSubAreasModal && (
        <Form onSubmit={onManageSubAreasModalSaveClick}>
          <div className="modal modal-lg">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title">{selectedArea?.Name}</h5>
                    <span className="light-text">Manage linked sub-areas</span>
                  </div>
                  <input type="text" className="modal-header-search form-control" placeholder="search" value={subAreaSearchString} onChange={onSubAreaSearchChange} />
                </div>
                <div className="modal-body">
                  <FormSection>
                    <div className="form-section-header">
                      <h5>Link Type</h5>
                      <span>Choose how you would like to have sub-areas linked to this area</span>
                      <div
                        className="flex flex-column gap-2 light-text"
                        style={{
                          borderLeft: 'solid 2px #ddd',
                          paddingLeft: '1rem',
                          margin: '1rem 0',
                        }}>
                        <small>
                          <b>Automatic</b>
                          <div>
                            Links all existing <b>and</b> future sub-areas
                          </div>
                        </small>
                        <small>
                          <b>Custom</b>
                          <div>Manually choose which sub-areas to link</div>
                        </small>
                      </div>
                    </div>
                    <FormInput
                      id="SubareaLinkType"
                      name="SubareaLinkType"
                      type="select"
                      items={[
                        { key: 'automatic', value: 'Automatic' },
                        { key: 'custom', value: 'Custom' },
                      ]}
                      autoComplete="off"
                      value={selectedArea?.SubareaLinkType}
                      placeholder="Link Type"
                      onChange={onAreaChange}
                    />
                  </FormSection>
                  {selectedArea.SubareaLinkType === 'custom' && (
                    <FormSection>
                      <div className="form-section-header">
                        <div>
                          <h5>Sub-areas</h5>
                          <span>Choose which sub-areas you would like to link to this area</span>
                        </div>
                        <div className="btn-group my-2">
                          <button className="btn btn-secondary" type="button" onClick={onManageSubAreasModalSelectAllClick}>
                            <div className="material-symbols-rounded">check_circle_outline</div>Select all
                          </button>
                          <button className="btn btn-outline-secondary" type="button" onClick={onManageSubAreasModalSelectNoneClick}>
                            <div className="material-symbols-rounded">circle</div>Select none
                          </button>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: 0,
                          margin: '0 -2rem',
                        }}>
                        <table className="table table--no-head-border table--no-foot-border">
                          <colgroup>
                            <col style={{ width: '80px' }} />
                            <col />
                          </colgroup>
                          <tbody>
                            {subAreaList
                              ?.filter((subarea) => !subAreaSearchString || subarea.Name.toLowerCase().indexOf(subAreaSearchString) >= 0)
                              .map((subarea, index) => {
                                let checked = !!selectedSubAreaList?.find((_: any) => _.Id === subarea.Id);
                                let baseColor = colors.randomFromString(subarea.Name);
                                let backgroundColor = colors.blend(baseColor, new colors([255, 255, 255]), 'hex', 0.9)._hex;

                                return (
                                  <tr key={index}>
                                    <td style={{ textAlign: 'center', width: 30 }}>
                                      <input
                                        autoComplete="off"
                                        type="checkbox"
                                        className="form-check-input"
                                        id={subarea.Id.toString()}
                                        checked={!!checked}
                                        onChange={(event) => onSelectSubAreaChanged(event, subarea)}
                                      />
                                    </td>
                                    <td style={{ paddingLeft: 0 }}>
                                      <div className="d-flex flex-row gap-3 align-items-center">
                                        <span className="status-label-stack">
                                          <span className="status-label" key={index} style={{ backgroundColor }} title={`${subarea.Name}`}>
                                            {subarea.Name.charAt(0)}
                                          </span>
                                        </span>
                                        <div>
                                          <div style={{ maxWidth: 400, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', marginBottom: -5 }}>
                                            <b>{subarea.Name}</b>
                                          </div>
                                          <small className="light-text d-inline-block">
                                            <div>
                                              <div>{/* <b>{subarea.EmailAddress}</b> */}</div>
                                            </div>
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </FormSection>
                  )}
                </div>
                <div className="modal-footer">
                  <button className="btn btn-sm btn-outline-secondary" onClick={onModalCancelClick}>
                    Cancel
                  </button>
                  <button className="btn btn-sm btn-primary" type="submit" disabled={!showManageSubAreasModalSaveButton}>
                    Save
                  </button>
                </div>
                {showModalLoader && (
                  <div className="card-loader">
                    <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                    </svg>
                  </div>
                )}
              </div>
              <div className="modal-backdrop fade show" />
            </div>
          </div>
        </Form>
      )}
      <div className="page-buttons">
        <div className="flex flex-row gap-2">
          <button className="btn btn-sm btn-primary justify-content-center" onClick={onAddClick}>
            Add Area(s)
          </button>
        </div>
      </div>
      <div className="grid" ref={containerRef}>
        {areaList
          .filter((area) => !search || area.Name.toLowerCase().indexOf(search) >= 0)
          .map((area) => (
            <AreaCard key={area.Id} area={area} onEditClick={onEditClick} onDeleteClick={onDeleteClick} onManageSubAreasClick={onManageSubAreasClick} />
          ))}
      </div>
      {showLoader && (
        <div className="dashboard__content__loader">
          <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
          </svg>
        </div>
      )}
    </>
  );
};

export default withRouter(Areas);

import { useContext } from 'react';
import { Store } from '../store/Store';
import { useParams, useSearchParams } from 'react-router-dom';

import lodash from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import B2CClientConnector from '../interfaces/B2CClientConnector';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const colors = require('ac-colors');

const Business2CloudConnectorManageCard = ({
  clientConnector,
  userList,
  onAssignUserClick,
  onEditClientConnectorClick,
  onDisconnectClientConnectorClick,
  onDeleteClientConnectorClick,
}: {
  clientConnector: B2CClientConnector;
  userList: any[] | undefined;
  onAssignUserClick: (clientConnector: B2CClientConnector) => void;
  onEditClientConnectorClick: (clientConnector: B2CClientConnector) => void;
  onDisconnectClientConnectorClick: (clientConnector: B2CClientConnector) => Promise<any>;
  onDeleteClientConnectorClick: (clientConnector: B2CClientConnector) => Promise<any>;
}) => {
  const { connectorType } = useParams();

  const [searchParams] = useSearchParams();

  const store = useContext(Store).store;
  const search = searchParams.get('search') as string;

  const users =
    clientConnector.Properties.find((_: any) => _.Name === 'portal.user')
      ?.Value?.trim()
      .split(' ')
      .map((user: any) => userList?.find((_) => _.Id === user)) || [];

  const filter =
    !search ||
    clientConnector.Name.toLowerCase().indexOf(search) >= 0 ||
    clientConnector.Name.toLowerCase().indexOf(search) >= 0 ||
    users.filter(
      (user: any) =>
        !search ||
        user?.FirstName?.toLowerCase().indexOf(search) >= 0 ||
        user?.LastName?.toLowerCase().indexOf(search) >= 0 ||
        user?.EmailAddress?.toLowerCase().indexOf(search) >= 0
    ).length > 0;

  if (!filter) return <></>;

  let manuallyTriggeredAtUtcCheck = clientConnector.Log?.ManuallyTriggeredAtUtc
    ? new Date(new Date(clientConnector.Log.ManuallyTriggeredAtUtc).getTime() + 1000 * 60 * 30)
    : new Date();

  let automaticallyTriggeredAtUtcCheck = clientConnector.Log?.AutomaticallyTriggeredAtUtc
    ? new Date(new Date(clientConnector.Log.AutomaticallyTriggeredAtUtc).getTime() + 1000 * 60 * 30)
    : new Date();

  let hasWarning =
    !clientConnector.Log?.CompletedAtUtc &&
    ((!!clientConnector.Log?.ManuallyTriggeredAtUtc && manuallyTriggeredAtUtcCheck < new Date()) ||
      (automaticallyTriggeredAtUtcCheck > manuallyTriggeredAtUtcCheck &&
        !!clientConnector.Log?.AutomaticallyTriggeredAtUtc &&
        automaticallyTriggeredAtUtcCheck < new Date()));

  const renderStatusIcon = () => {
    if (clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED') return <></>;

    if (hasWarning && !clientConnector.Log?.HasError) return <span className={`material-symbols-rounded user-select-none warning`}>warning</span>;
    else if (!hasWarning && clientConnector.Log?.HasError) return <span className={`material-symbols-rounded user-select-none alert`}>error</span>;

    if (clientConnector.Log && !clientConnector.Log?.CompletedAtUtc)
      return <span className={`material-symbols-rounded user-select-none pending`}>schedule</span>;

    if (clientConnector.Log?.CompletedAtUtc) return <span className={`material-symbols-rounded user-select-none active`}>check_circle</span>;

    return <span className={`material-symbols-rounded user-select-none pending`}>pending</span>;
  };

  const renderBody = () => {
    if (!users.length) return '';

    return (
      <div className="status-label-stack">
        {lodash
          .take(users, 10)
          .filter((_: any) => _)
          .map((user: any, index: number) => {
            let baseColor = colors.randomFromString(user.FirstName + user.LastName);
            let backgroundColor = colors.blend(baseColor, new colors([255, 255, 255]), 'hex', 0.9)._hex;

            return (
              <span className="status-label" key={index} style={{ backgroundColor }} title={`${user.FirstName} ${user.LastName}`}>
                {user.FirstName.charAt(0)}
                {user.LastName?.charAt(0)}
              </span>
            );
          })}
        {users.length > 10 && <span className="status-label additional">+ {users.length - 10}</span>}
      </div>
    );
  };

  return (
    <div
      className={`tw-shadow tw-transition-all hover:tw-shadow-lg hover:tw-ring tw-ring-offset-1 tw-ring-blue-500 tw-no-underline tw-font-semibold !tw-text-slate-900 card card-sm  ${
        clientConnector.Status === 'DISCONNECTED' || clientConnector.Status === 'DELETED' ? 'disabled' : ''
      }`}
      style={{ height: 230 }}>
      <div className="card-header align-items-start">
        <div style={{ maxWidth: '80%' }}>
          <div className="d-flex flex-column gap-3">
            <span className="text-uppercase fw-bold">
              {(clientConnector.Status === 'DELETED' && (
                <span className="p-1 px-2 fs-11 text-danger bg-danger bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                  deleted
                </span>
              )) ||
                (clientConnector.Status === 'DISCONNECTED' && (
                  <span className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                    disconnected
                  </span>
                )) ||
                (clientConnector.Log?.Forbidden && (
                  <span className="p-1 px-2 fs-11 text-warning bg-warning bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                    unkown
                  </span>
                )) ||
                (clientConnector.Status !== 'DISCONNECTED' && (
                  <span className="p-1 px-2 fs-11 text-success bg-success bg-opacity-10 rounded-1 d-inline" style={{ marginLeft: '-0.2rem' }}>
                    connected
                  </span>
                ))}
            </span>
            <span className="d-flex flex-column">
            {clientConnector.Name !== clientConnector.OriginalName && <small className="fw-bold lighter-text fs-11">{clientConnector.OriginalName}</small>}
              <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}>
                {clientConnector.Name}
              </span>
            </span>
          </div>
        </div>
        <span className="ms-auto">{renderStatusIcon()}</span>
      </div>
      <div className="card-body">{renderBody()}</div>
      <div className="card-footer gap-2 mt-auto">
        <div className="d-flex flex-row gap-1">
          {clientConnector.Status !== 'DISCONNECTED' && clientConnector.Status !== 'DELETED' && (
            <button className="btn btn-sm btn-primary" onClick={() => onAssignUserClick(clientConnector)}>
              Manage users
            </button>
          )}
          {clientConnector.Status !== 'DELETED' && store.user.roleLevel <= 2 && (
            <div className="d-flex flex-row gap-2 flex-grow ms-auto">
              <button className="btn btn-sm btn-round btn-secondary" title="Edit" onClick={() => onEditClientConnectorClick(clientConnector)}>
                <span className="material-symbols-rounded">edit</span>
              </button>
              <button className="btn btn-sm btn-round btn-danger" title="Delete" onClick={() => onDeleteClientConnectorClick(clientConnector)}>
                <span className="material-symbols-rounded">delete</span>
              </button>
              {connectorType === 'xero' && clientConnector.Status !== 'DISCONNECTED' && (
                <button
                  className="btn btn-sm btn-round btn-outline-danger"
                  title="Disconnect"
                  onClick={() => onDisconnectClientConnectorClick(clientConnector)}>
                  <span className="material-symbols-rounded">link_off</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Business2CloudConnectorManageCard;

import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';

const FormInput = forwardRef((props: any, ref) => {
  const [valid, setValid] = useState(true);
  const [message, setMessage] = useState(' ');
  const [match, setMatch] = useState<any>();

  const onChange = (event: ChangeEvent) => {
    props.onChange && props.onChange(event);
  };

  const onFocus = (event: ChangeEvent) => {
    props.onFocus && props.onFocus(event);
  };

  const onBlur = () => {
    setValid(true);
    validate();

    if (!!match?.ref?.current?.value) {
      match?.ref?.current?.reset();
      match?.ref?.current?.validate();
    }
  };

  const validateCallback = (result: any) => {
    setValid(result.valid);
    setMessage(result.message);
  };

  const validate = () => {
    if (props.type !== 'checkbox' && props.required === undefined) {
      props = { ...props, required: true };
    }

    if (props.required && !props.value) {
      setValid(false);
      setMessage('required');

      return false;
    }

    if (!!match?.ref?.current?.value && !!props.value && match.ref.current.value !== props.value) {
      setValid(false);
      setMessage(`Does not match ${match.props.placeholder}`);

      return false;
    }

    if (props.validate) {
      const result = props.validate(props.value, validateCallback);

      if (result) {
        setValid(result.valid);

        if (!result.valid) {
          setMessage(result.message);

          return false;
        }
      }
    }

    return true;
  };

  useImperativeHandle(ref, () => ({
    validate,
    match: (match: any) => setMatch(match),
    reset: () => setValid(true),
    value: props.value,
    id: props.id,
  }));

  return (
    <div className={`form-section-control flex-grow-1 ${props.className}`}>
      <div className="flex flex-row gap-2">
        <div className={`flex justify-content-center ${props.type === 'checkbox' ? 'flex-row mt-2 align-items-center' : 'flex-column'} flex-grow-1 gap-2`}>
          {props.label && props.type !== 'checkbox' && <label htmlFor={props.id}>{props.label} <span className="tw-text-red-500 tw-text-xs tw-font-semibold">{props.required && "*"}</span></label>}
          {props.type === 'select' ? (
            <select id={props.id} name={props.name} className="form-select" onChange={onChange} defaultValue={props.value}>
              {props.items &&
                props.items.map((item: any) => {
                  return (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  );
                })}
            </select>
          ) : (
            <input
              autoFocus={props.autoFocus}
              type={props.type}
              id={props.id}
              className={`flex-grow ${props.type === 'checkbox' ? 'form-check-input' : 'form-control'} ${valid ? 'valid' : 'invalid'}`}
              aria-label={props.placeholder}
              aria-describedby={props.placeholder}
              placeholder={props.placeholder}
              disabled={props.disabled}
              value={props.value || ''}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyUp={props.onKeyUp}
              autoComplete={props.autoComplete}
            />
          )}
          {props.type === 'checkbox' && <label className="me-auto">{props.label || props.placeholder}</label>}
        </div>
      </div>
      {props.type !== 'checkbox' && (
        <span className={`form-section-control-error flex flex-row justify-content-space-between align-items-center gap-1 ${valid ? 'hide' : 'show'}`}>
          <span className="material-symbols-rounded">error</span>
          {message}
        </span>
      )}
    </div>
  );
});

FormInput.defaultProps = {
  __TYPE: 'FormInput',
};

export default FormInput;

import React, { Fragment, useEffect } from 'react';
import { Store } from '../store/Store';
import { withRouter } from "../routes/withRouter";
import { ApiService } from '../services/apiService';
import { REDUCER_ACTION_SET } from '../store/types';
import { SORT_DIRECTION, sortList } from '../services/helperSort';

const api = ApiService.getInstance();

const AssignUsersHelperTables = (props: any) => {
  const s = React.useContext(Store);
  const store = s.store;
  const dispatch = s.dispatch;

  const [users, setUsers] = React.useState([]);
  const [helperTables, setHelperTables] = React.useState([]);
  const [values, setValues] = React.useState<any>({});

  const getUsers = async () => {
    let usersFetched = await api.getUsers(s.store?.organisationSelected?.organisationId);
    if (usersFetched) {
      // sort users
      //const newValues = { ...values };
      usersFetched = sortList(usersFetched, ['FirstName'], SORT_DIRECTION.ASC);
      //newValues.sortListUsersDirection = SORT_DIRECTION.ASC;
      //setValues(newValues);
      setUsers(usersFetched);
      // const storeAction = {
      //     type: REDUCER_ACTION_SET.SET_AREAS,
      //     payload: usersFetched,
      // };
      // dispatch(storeAction);
    }
  };

  const getHelperTables = async () => {
    let helperTablesFetched = await api.getHelperTables(store?.organisationSelected?.organisationId);
    if (helperTablesFetched) {
      // sort groups
      //const newValues = { ...values };
      helperTablesFetched = sortList(helperTablesFetched, ['Name'], SORT_DIRECTION.ASC);
      //newValues.sortListHelperTablesDirection = SORT_DIRECTION.ASC;

      //setValues(newValues);
      setHelperTables(helperTablesFetched);
      // const storeAction = {
      //     type: REDUCER_ACTION_SET.SET_SUB_AREAS,
      //     payload: subAreasFetched,
      // };
      // dispatch(storeAction);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      getUsers();
      getHelperTables();

      dispatch({
        type: REDUCER_ACTION_SET.SET_BREAD_CRUMB,
        payload: 'Assign Users to Helper Tables',
      });
    })();
  }, [store?.organisationSelected?.organisationId]);

  enum ValueStoreTypes {
    STRING,
    ARRAY,
  }

  const handleChange = (e: any, names: string[] | string, valueStoreType?: ValueStoreTypes) => {
    const newValues = { ...values };

    if (!Array.isArray(names)) {
      names = [names];
    }

    let newValuesStore = newValues;

    if (names[0] === 'userEdit') {
      newValues.editAddShow = true;
      newValues.groupMappings = { helperTables: {} };
      newValues.groupMappings.userId = e.target.value.Id;
      // newValues.groupMappings.helperTables[newValues.groupMappings.userId] = {};
      // newValues.groupMappings.helperTables[newValues.groupMappings.userId].checked = {};
      //
      // e.target.value.HelperTables && Array.isArray(e.target.value.HelperTables) && e.target.value.HelperTables.forEach((helperTables: any) => {
      //
      //

      //   newValues.groupMappings.helperTables[newValues.groupMappings.userId].checked[helperTables.Id] = true;
      // });

      users &&
        Array.isArray(users) &&
        users.forEach((user: any) => {
          newValues.groupMappings.helperTables[user.Id] = {};
          newValues.groupMappings.helperTables[user.Id].checked = {};
          user.HelperTables &&
            Array.isArray(user.HelperTables) &&
            user.HelperTables.forEach((helperTables: any) => {
              newValues.groupMappings.helperTables[user.Id].checked[helperTables.Id] = true;
            });
        });
    }

    for (let i = 0; i < names.length - 1; i++) {
      const name = names[i];
      if (!newValuesStore[name]) {
        newValuesStore[name] = {};
      }
      newValuesStore = newValuesStore[name];
    }

    if (valueStoreType === ValueStoreTypes.ARRAY && !Array.isArray(newValuesStore[names[names.length - 1]])) {
      newValuesStore[names[names.length - 1]] = [];
    }
    if (valueStoreType === ValueStoreTypes.ARRAY) {
      newValuesStore[names[names.length - 1]].push(e.target.value);
    } else {
      newValuesStore[names[names.length - 1]] = e.target.value;
    }

    setValues(newValues);
  };

  const linkUserToHelperTable = async () => {
    if (values.applyFor === 'allCurrentAndFuture') {
      //const result = await api.linkAreaWithSubAreaList(values.area, undefined, true);
    } else {
      //

      if (!values.groupMappings || !values.groupMappings.helperTables) return;

      //addPermissionAreaSubArea
      const userIdKeys = Object.keys(values.groupMappings.helperTables);

      for (let i = 0; i < userIdKeys.length; i++) {
        const userIdKey = userIdKeys[i];
        await api.deleteLinkUserToHelperTable(userIdKey, s.store?.organisationSelected?.organisationId);
        //const groupData = values.groupMappings.groups[userIdKey];

        const helperTableKeys = Object.keys(values.groupMappings.helperTables[userIdKey].checked);
        const helperTables: string[] = [];
        for (let j = 0; j < helperTableKeys.length; j++) {
          if (values.groupMappings.helperTables[userIdKey].checked[helperTableKeys[j]] === true) {
            helperTables.push(helperTableKeys[j]);
          }
        }
        const result = await api.linkUserToHelperTable(userIdKey, helperTables);
      }
      getUsers();
      getHelperTables();
      setValues({ ...values, editAddShow: false });
    }
    //getUsers();
  };

  const unAssign = async (userId: string) => {
    await api.deleteLinkUserToHelperTable(userId, s.store?.organisationSelected?.organisationId);
    getUsers();
    //getHelperTables();
  };

  const sortListUsers = (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(users, ['FirstName'], sortDirection);

    const newValues = { ...values };
    newValues.sortListUsersDirection = sortDirection;
    setValues(newValues);
    setUsers(sortedList as any);
  };

  const sortListHelperTable= (sortDirection: SORT_DIRECTION) => {
    const sortedList = sortList(helperTables, ['Name'], sortDirection);

    const newValues = { ...values };
    newValues.sortListHelperTablesDirection = sortDirection;
    setValues(newValues);
    setHelperTables(sortedList as any);
  };

  return (
    <div className='row justify-content-center'>
      <div className='col-11'>
        <h3 className='page-heading mb-4'>Assign Users to Helper Tables</h3>
        {!values.editAddShow ? (
          <Fragment>
            <div className='card card--height-limit'>
              <ul className='list-group list-group-flush'>
                <li className='list-group-item' style={{ borderBottom: '1px solid #dee2e6' }}>
                  <h5 className='my-2'>Users assigned to Helper Tables</h5>
                </li>
              </ul>
              <table className='table table--no-head-border'>
                <colgroup>
                  <col style={{ width: '80%' }} />
                  <col style={{ width: '20%' }} />
                </colgroup>
                <thead>
                  <tr className='table-header'>
                    <th style={{cursor: 'pointer'}}>
                      <div style={{display: 'inline-block'}}
                        onClick={(e) => {
                          sortListUsers(values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC
                            ? SORT_DIRECTION.DEC 
                            : SORT_DIRECTION.ASC
                          );
                      }}>  
                        Name 
                        <i className={values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC 
                          ? 'fa-solid fa-arrow-down-short-wide ms-1'
                          : 'fa-solid fa-arrow-up-wide-short ms-1'
                        }/>
                      </div>
                      <div style={{paddingLeft: '10px', display: 'inline-block'}}>
                        <input
                          className='form-control form-control-sm'
                          type='text'
                          id='filterSecurityUserHelperTable'
                          name='filterSecurityUserHelperTable'
                          placeholder='Search'
                          value={values.filterHelperTable}
                          onChange={(e) => handleChange(e, 'filterSecurityUserHelperTable')}
                        />
                      </div>
                    </th>
                    <th>Manage</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user: any) => {

                    if (values.filterSecurityUserHelperTable && values.filterSecurityUserHelperTable !== '' && `${user.FirstName} ${user.LastName}`.toLowerCase().indexOf(values.filterSecurityUserHelperTable.toLowerCase()) === -1) {
                      return <Fragment></Fragment>;
                    }

                    let linkedHelperTable = '';
                    user.HelperTables &&
                      Array.isArray(user.HelperTables) &&
                      user.HelperTables.forEach((helperTable: any) => {
                        if (linkedHelperTable !== '') linkedHelperTable += ', ';
                        linkedHelperTable += helperTable.Name;
                      });
                    return (
                      <tr>
                        <td className='tools'>
                          <span>
                            {user.FirstName} {user.LastName}
                          </span>
                          <span className='status-label'>
                            <i className='fa-solid fa-user-plus' />
                            ASSIGNED TO
                          </span>
                          <span>{linkedHelperTable}</span>
                        </td>
                        <td>
                          <div className='tools'>
                            <button
                              className='btn btn-outline-primary btn-sm'
                              onClick={() => {
                                handleChange({ target: { value: user } }, 'userEdit');
                              }}>
                              <i className='fa-solid fa-pen-to-square' />
                              Edit
                            </button>
                            <button
                              className='btn btn-outline-danger btn-sm'
                              onClick={() => {
                                unAssign(user.Id);
                              }}>
                              <i className='fa-solid fa-user-slash' />
                              Unassign
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <h3 className='page-heading has-tools mb-4'>
              Create new link
              <div className='tools'>
                <button
                  className='btn btn-outline-secondary fw-bold'
                  onClick={() => {
                    handleChange({ target: { value: false } }, 'editAddShow');
                  }}>
                  <i className='fa-solid fa-arrow-left' />
                  Back to links
                </button>
              </div>
            </h3>
            <div className='card card--height-limit no-overflow'>
              <div className='card__linking-container p-4'>
                <div className='card__linking-container--left card-body border p-0'>
                  <div className='p-3 pb-0'>
                    <div className='has-tools'>
                      <h5 className='mb-0'>Users</h5>
                      <div className='btn btn-outline-secondary btn-sm btn-sort-width'
                        onClick={(e) => {
                        sortListUsers(values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC
                          ? SORT_DIRECTION.DEC 
                          : SORT_DIRECTION.ASC
                        );
                      }}
                      >
                      {values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC
                        ? 'Ascending' 
                        : 'Descending'
                      }
                      <i className={values.sortListUsersDirection === undefined || values.sortListUsersDirection === SORT_DIRECTION.ASC 
                        ? 'fa-solid fa-arrow-down-short-wide ms-1'
                        : 'fa-solid fa-arrow-up-wide-short ms-1'
                      } />
                      </div>
                    </div>
                    <input
                      type='text'
                      className='form-control form-control-sm my-3'
                      placeholder='Search Users'
                      onChange={(e) => {
                        handleChange(e, 'filterUsers');
                      }}
                    />
                  </div>
                  <hr className='my-4 mb-0' />
                  <div className='form-check__container form-check__container--left'>
                    {users.map((user: any) => {
                      if (
                        values.filterUsers &&
                        values.filterUsers !== '' &&
                        `${user.FirstName} ${user.LastName}`.toLowerCase().indexOf(values.filterUsers.toLowerCase()) === -1
                      ) {
                        return <Fragment></Fragment>;
                      }

                      return (
                        <div className='form-check'>
                          <input
                            autoComplete='off'
                            type='radio'
                            id={'user' + user.Id}
                            name='user'
                            className='form-check-input'
                            value={user.Id}
                            checked={values.groupMappings && values.groupMappings?.userId === user.Id}
                            onChange={(e) => {
                              handleChange(e, ['groupMappings', 'userId']);
                            }}
                          />
                          <label className='form-check-label' htmlFor={'user' + user.Id}>
                            {user.FirstName} {user.LastName}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='card__linking-container--middle bg-light border-top border-bottom d-flex align-items-center justify-content-center'>
                  <i className='fa-solid fa-arrow-right' />
                </div>
                <div className='card__linking-container--right card-body border p-0'>
                  <div className='p-3 pb-0'>
                    <div className='has-tools'>
                      <h5 className='mb-0'>Helper Tables</h5>
                      <div className='btn btn-outline-secondary btn-sm btn-sort-width'
                        onClick={(e) => {
                        sortListHelperTable(values.sortListHelperTablesDirection === undefined || values.sortListHelperTablesDirection === SORT_DIRECTION.ASC
                          ? SORT_DIRECTION.DEC 
                          : SORT_DIRECTION.ASC
                        );
                      }}
                      >
                      {values.sortListHelperTablesDirection === undefined || values.sortListHelperTablesDirection === SORT_DIRECTION.ASC
                        ? 'Ascending' 
                        : 'Descending'
                      }
                      <i className={values.sortListHelperTablesDirection === undefined || values.sortListHelperTablesDirection === SORT_DIRECTION.ASC 
                        ? 'fa-solid fa-arrow-down-short-wide ms-1'
                        : 'fa-solid fa-arrow-up-wide-short ms-1'
                      } />
                      </div>
                    </div>
                    <input
                      type='text'
                      className='form-control form-control-sm my-3'
                      placeholder='Search Helper Tables'
                      onChange={(e) => {
                        handleChange(e, 'filterHelperTables');
                      }}
                    />

                    {/* <div>
                                <div className="form-check">
                                    <input
                                        autoComplete="off"
                                        type="radio"
                                        id={'allCurrent'}
                                        name="allCurrent"
                                        className="form-check-input"
                                        value={'allCurrent'}
                                        checked={
                                            values.applyFor === 'allCurrent'
                                        }
                                        onChange={(e) => {
                                            const newHelperTables: any = {};
                                            helperTables.forEach(
                                                (helperTable: any) => {
                                                    newHelperTables[
                                                        helperTable.Id
                                                    ] = true;
                                                }
                                            );
                                            setValues({
                                                ...values,
                                                helperTable: newHelperTables,
                                                applyFor: 'allCurrent',
                                            });
                                            //handleChange(e, "applyFor");
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={'allCurrent'}
                                    >
                                        Select all current
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        autoComplete="off"
                                        type="radio"
                                        id={'allCurrentAndFuture'}
                                        name="allCurrentAndFuture"
                                        className="form-check-input"
                                        value={'allCurrentAndFuture'}
                                        checked={
                                            values.applyFor ===
                                            'allCurrentAndFuture'
                                        }
                                        onChange={(e) => {
                                            handleChange(e, 'applyFor');
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={'allCurrentAndFuture'}
                                    >
                                        Select all current and future
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        autoComplete="off"
                                        type="radio"
                                        id={'onlySelected'}
                                        name="onlySelected"
                                        className="form-check-input"
                                        value={'onlySelected'}
                                        checked={
                                            values.applyFor === 'onlySelected'
                                        }
                                        onChange={(e) => {
                                            handleChange(e, 'applyFor');
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={'onlySelected'}
                                    >
                                        Only selected
                                    </label>
                                </div>
                            </div> */}
                  </div>
                  <hr className='my-4 mb-0' />
                  <div className='form-check__container form-check__container--right'>
                    {users.map((user: any) => {
                      if (!values.groupMappings || values.groupMappings?.userId !== user.Id) return;
                      if (!Array.isArray(helperTables)) return;

                      return helperTables.map((helperTable: any) => {
                        if (
                          values.filterHelperTables &&
                          values.filterHelperTables !== '' &&
                          helperTable.Name.toLowerCase().indexOf(values.filterHelperTables.toLowerCase()) === -1
                        ) {
                          return <Fragment></Fragment>;
                        }

                        return (
                          <div className='form-check'>
                            <input
                              autoComplete='off'
                              type='checkbox'
                              className='form-check-input'
                              id={'helperTable' + helperTable.Id}
                              checked={
                                values.groupMappings &&
                                values.groupMappings.helperTables &&
                                values.groupMappings.helperTables[`${user.Id}`] &&
                                values.groupMappings.helperTables[`${user.Id}`].checked &&
                                values.groupMappings.helperTables[`${user.Id}`].checked[helperTable.Id]
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                handleChange(
                                  {
                                    target: { value: e.target.checked },
                                  },
                                  ['groupMappings', 'helperTables', `${user.Id}`, 'checked', helperTable.Id]
                                );
                              }}
                            />
                            <label className='form-check-label' htmlFor={'helperTable' + helperTable.Id}>
                              {helperTable.Name}
                            </label>
                          </div>
                        );
                      });
                    })}
                  </div>
                </div>
              </div>
              <div className='text-end p-4 pt-0'>
                <button
                  className='btn btn-success fw-bold d-inline'
                  onClick={() => {
                    linkUserToHelperTable();
                  }}>
                  Create Link
                  <i className='fa-solid fa-check ms-2' />
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default withRouter(AssignUsersHelperTables);
